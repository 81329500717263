import Link from 'next/link'
import { useEffect } from 'react'
import Icon from '@/components/icon/icon'
import styleConfig from '@/styles/style-config'
import { useRouter } from 'next/router'
import { getBackgroundFilter } from '@/lib/search-spring'
import { addForwardSlash, restructureMenus } from '@/lib/helpers'
import { navClickGTM } from '@/lib/gtm'

const MegaMenu = (props) => {
  const router = useRouter()
  const closeAndRedirect = (e, url, title, menuLevel = 'header', redirectUrl) => {
    //GTM
    navClickGTM(url, title, menuLevel, 'header')
    //

    e.preventDefault()

    props.closeMenu()
    router.push(addForwardSlash(redirectUrl))
  }
  const structuredMenus = props.isWeb && restructureMenus(props.submenuCollection)
  const menusCollection = structuredMenus.filter((value) => Object.keys(value).length !== 0)

  const isWeb = props.isWeb

  return (
    <>
      <div className="border-t border-gray-700 megamenu w-full absolute left-0 bg-white text-white">
        <div className="megamenu__item-container flex w-full mx-auto bg-white text-black lg:px-6 xl:px-15">
          {
            menusCollection?.length > 0 &&
            menusCollection?.filter(menu => {
              let content = menu?.content
              let mobileOnly = 0

              if (menu?.mobileOnly) {
                mobileOnly++
              }

              if (menu?.mobileOnly2) {
                mobileOnly++
              }

              return content > mobileOnly
            })?.map((menu, key) => {
              return menu.heading != '' ? (
                <div
                  className="w-2/12 pt-60.5 lg:pb-18 xl:pb-108 border-l border-borderGray mr-2.5 space-y-10 pl-10l"
                  key={key}
                >
                  {
                    !menu?.mobileOnly && (
                      <div>
                        <p className="mb-3 text-black leading-18 uppercase text-sm font-normal ml-0">
                          {menu.makeTitleLink ? (
                            <Link
                              href={addForwardSlash(menu.slug)}
                              className="hover:text-gwOrange"
                              onClick={(e) =>
                                closeAndRedirect(
                                  e,
                                  menu?.slugDefault,
                                  menu?.headingDefault,
                                  `${props?.parentTitle}::${menu?.headingDefault}`,
                                  menu.slug
                                )
                              }
                              prefetch={false}
                            >
                              {menu.heading}
                            </Link>
                          ) : (
                            menu.heading
                          )}
                        </p>
                        {menu.sectionImage && menu.sectionImage.url != '' && (
                          <div
                            className="desktop-menu-img"
                            style={{ backgroundImage: `url(${menu.sectionImage.url})` }}
                          ></div>
                        )}
                        <ul>
                          {menu.links &&
                            menu.links.length > 0 &&
                            menu.links
                              .filter((submenu) => submenu?.link !== undefined && !submenu?.mobileOnly)
                              .map((submenu, i) => {
                                return (
                                  <li
                                    className="2xs:text-sm 2xs:leading-18 lg:text-sm lg:leading-20 mb-2.5 font-normal"
                                    key={i}
                                  >
                                    <Link
                                      key={i}
                                      onClick={(e) =>
                                        closeAndRedirect(
                                          e,
                                          submenu?.linkDefault,
                                          submenu?.titleDefault,
                                          `${props?.parentTitle}::${menu?.headingDefault}::${submenu?.titleDefault}`,
                                          submenu.link
                                        )
                                      }
                                      href={addForwardSlash(submenu.link)}
                                      className="hover:text-gwOrange"
                                      prefetch={false}
                                    >
                                      {submenu.title}
                                    </Link>
                                  </li>
                                )
                              })}
                        </ul>
                      </div>
                    )
                  }
                  {
                    !menu?.mobileOnly2 && (
                      <div>
                        {menu.heading2 && (
                          <p className="mb-3 text-black leading-18 uppercase text-sm font-normal ml-0">
                            {menu.makeTitleLink2 ? (
                              <Link
                                href={addForwardSlash(menu.slug2)}
                                className="hover:text-gwOrange"
                                onClick={(e) =>
                                  closeAndRedirect(
                                    e,
                                    menu?.slug2Default,
                                    menu?.heading2Default,
                                    `${props?.parentTitle}::${menu?.heading2Default}`,
                                    menu.slug2
                                  )
                                }
                                prefetch={false}
                              >
                                {menu.heading2}
                              </Link>
                            ) : (
                              menu.heading2
                            )}
                          </p>
                        )}
                        {menu.sectionImage2 && menu.sectionImage2.url != '' && (
                          <div
                            className="desktop-menu-img"
                            style={{ backgroundImage: `url(${menu.sectionImage2.url})` }}
                          ></div>
                        )}
                        <ul>
                          {menu.links2 &&
                            menu.links2.length > 0 &&
                            menu.links2.map((submenu, i) => {
                              return (
                                <li
                                  className="2xs:text-sm 2xs:leading-18 lg:text-sm lg:leading-20 mb-2.5 font-normal"
                                  key={i}
                                >
                                  <Link
                                    key={i}
                                    onClick={(e) =>
                                      closeAndRedirect(
                                        e,
                                        submenu?.linkDefault,
                                        submenu?.titleDefault,
                                        `${props?.parentTitle}::${menu.heading2Default}::${submenu?.titleDefault}`,
                                        submenu.link
                                      )
                                    }
                                    className="hover:text-gwOrange"
                                    href={addForwardSlash(submenu.link)}
                                    prefetch={false}
                                  >
                                    {submenu.title}
                                  </Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  }
                </div>
              ) : null
            })}
        </div>
      </div>
      {/* {isWeb != false && ( */}
      <style jsx>
        {`
          .megamenu {
            top: 100%;
            opacity: 0;
            transform: scaleY(0);
            transform-origin: top;
            transition: all 0.2s ease-out;
            z-index: -1;
          }

          .megamenu__item-container {
            opacity: 0;
            position: relative;
            transition: all 0.2s ease-out 0.3s;
          }

          .megamenu__promotion {
            width: 275px;
            height: 275px;
          }

          @media (max-width: ${styleConfig.theme.screens.md}) {
            .megamenu {
              display: none;
            }
          }

          @media (min-width: 1200px) {
            .megamenu__promotion {
              width: 315px;
              height: 315px;
            }
          }

          ul,
          ol {
            list-style: none;
          }
        `}
      </style>
      {/* )} */}
    </>
  )
}

export default MegaMenu
