import Link from 'next/link'
import Icon from '@/components/icon/icon'
import MobileSearchBox from '@/components/search/mobile-search-box'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import regions from '@/config/regions'
import { trans } from '@/lib/locale-helper'
import { getRegion, getRegionNumber } from '@/lib/region-helper'
import { useRef, useEffect } from 'react'
import { categoryPageGTM } from '@/lib/gtm'

const SearchBox = (props) => {
  const router = useRouter()
  const { publicRuntimeConfig } = getConfig()
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const topSellerHalf = Math.ceil(props.topSellerData.length / 2)
  const searchResultsHalf = Math.ceil(props.searchData.length / 2)
  const firstHalf_topSeller = topSellerHalf > 0 ? props.topSellerData.slice(0, topSellerHalf) : []
  const secondHalf_topSeller = topSellerHalf > 0 ? props.topSellerData.slice(topSellerHalf) : []
  const firstHalf_searchData =
    searchResultsHalf > 0 ? props.searchData.slice(0, searchResultsHalf) : []
  const secondHalf_searchData =
    searchResultsHalf > 0 ? props.searchData.slice(searchResultsHalf) : []

  const defaultCountry = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

  let supportUrls = {
    en: 'https://support.gorewear.com/hc/en-us/requests/new',
    'en-gb': 'https://care.gorewear.com/hc/en-gb/requests/new',
    de: 'https://care.gorewear.com/hc/de/requests/new',
    es: 'https://care.gorewear.com/hc/es-es/requests/new',
    fr: 'https://care.gorewear.com/hc/fr/requests/new',
    it: 'https://care.gorewear.com/hc/it/requests/new',
  }

  let supportUrl = supportUrls[defaultCountry.localePrefix]

  if (defaultCountry.localePrefix == 'en' && defaultCountry.storeSource == 'EU') {
    supportUrl = supportUrls['en-gb']
  }

  const closeAndRedirect = (e, url) => {
    e.preventDefault()
    props.clearAllData(url)
  }

  //GTM
  useEffect(() => {
    if (props.topSellerData) {
      categoryPageGTM(props.topSellerData)
    }
  }, [props.topSellerData])

  //

  return (
    <>
      <div
        className={`search-box border-t border-gray-700 bg-white absolute z-20 w-full 2xs:pb-20 lg:pb-0 overflow-auto ${
          props.isOpened ? 'open-search' : ''
        }`}
      >
        <div className="desktop-search-box h-full">
          {!props.emptySearchResult && (
            <div className="search-lower w-full flex h-full">
              <div className="search-section-1 border-gray-700 mr-2.5 flex justify-center pt-60r pb-20">
                <Icon
                  className={`align-middle inline-block h-full text-gray-700 fill-current`}
                  viewBox={`0 0 34 34`}
                  size={34}
                  icon="target"
                />
              </div>
              <div className="search-section-2 border-l border-borderGray mr-2.5 pt-60r pb-20">
                <p className="leading-16r tracking-tightest m-0 text-black uppercase text-sm font-normal pl-10l">
                  {!props.isSearchResult
                    ? trans('popular-searches', 'Popular Searches')
                    : trans('search-results', 'Search Results')}
                </p>
                <ul className="mt-5 text-sm text-black leading-20 font-normal list-none pl-10l">
                  {!props.isSearchResult &&
                    props.popularSearches &&
                    props.popularSearches.map((item, key) => {
                      return (
                        <li className="mb-2" key={key}>
                          <Link
                            href={`/search?q=${item.searchQuery}`}
                            className="hover:text-gwOrange"
                            onClick={(e) => closeAndRedirect(e, `/search?q=${item.searchQuery}`)}
                          >
                            {item.searchQuery}
                          </Link>
                        </li>
                      )
                    })}
                  {props.isSearchResult &&
                    props.alternateSearchSuggestion &&
                    props.alternateSearchSuggestion.map((item, key) => {
                      return (
                        <li className="mb-2" key={key}>
                          <Link
                            href={`/search?q=${item.text}`}
                            className="hover:text-gwOrange"
                            onClick={(e) => closeAndRedirect(e, `/search?q=${item.text}`)}
                          >
                            {item.text}
                          </Link>
                        </li>
                      )
                    })}
                  {props.isSearchResult && (
                    <div className="mt-5 text-sm text-black">
                      <Link
                        href={`/search?q=${props.inputValue}`}
                        className="hover:text-gwOrange"
                        onClick={(e) => closeAndRedirect(e, `/search?q=${props.inputValue}`)}
                      >
                        Shop all "{props.inputValue}"
                      </Link>
                    </div>
                  )}
                </ul>
              </div>
              <div className="search-section-3 border-l border-borderGray pt-60r pb-20">
                <p className="leading-16r tracking-tightest m-0 text-black uppercase text-sm font-normal pl-10l">
                  {!props.isSearchResult
                    ? trans('top-sellers', 'Top Sellers')
                    : trans('product-results', 'Product Results')}
                </p>
                {!props.isSearchResult &&
                  firstHalf_topSeller.length > 0 &&
                  firstHalf_topSeller.map((topSellerData, index) => {
                    return (
                      <a
                        key={index}
                        className="cursor-pointer group"
                        onClick={(e) => closeAndRedirect(e, `${topSellerData.slug}`)}
                      >
                        <div className="w-full inline-flex xl:py-5 border-b border-borderGray search-product-card items-center pl-10l">
                          <img src={topSellerData.imageUrl} className="pc-image" />
                          <div className="product-info h-full  relative flex align-middle items-center pl-1">
                            <p className="text-sm leading-18 font-normal uppercase text-gray-600 absolute top-0 group-hover:text-gwOrange">
                              <span className="leading-18 font-primary uppercase">
                                {topSellerData.category.includes(' . ') ? (
                                  <>
                                    <span>
                                      {trans(
                                        topSellerData.category
                                          .split('.')[0]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        topSellerData.category.split('.')[0].trim()
                                      )}
                                    </span>
                                    <span> · </span>
                                    <span>
                                      {trans(
                                        topSellerData.category
                                          .split('.')[1]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        topSellerData.category.split('.')[1].trim()
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {trans(
                                      topSellerData.category.replace(' ', '-').toLowerCase(),
                                      topSellerData.category
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 group-hover:text-gwOrange">
                              {topSellerData.name}
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 font-primary absolute bottom-0 group-hover:text-gwOrange">
                              {country.currencySymbol}
                              {topSellerData.price}
                            </p>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                {props.isSearchResult &&
                  firstHalf_searchData.length > 0 &&
                  firstHalf_searchData.map((searchData, index) => {
                    return (
                      <a
                        key={index}
                        className="cursor-pointer group"
                        onClick={(e) => closeAndRedirect(e, `${searchData.slug}`)}
                      >
                        <div className="w-full inline-flex xl:py-5 border-b border-borderGray search-product-card items-center pl-1">
                          <img src={searchData.imageUrl} className="pc-image" />
                          <div className="product-info h-full  relative flex align-middle items-center pl-1">
                            <p className="text-sm leading-18 font-normal uppercase text-gray-600 absolute top-0 group-hover:text-gwOrange">
                              <span className="leading-18 font-primary uppercase">
                                {searchData.category.includes(' . ') ? (
                                  <>
                                    <span>
                                      {trans(
                                        searchData.category
                                          .split('.')[0]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        searchData.category.split('.')[0].trim()
                                      )}
                                    </span>
                                    <span> · </span>
                                    <span>
                                      {trans(
                                        searchData.category
                                          .split('.')[1]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        searchData.category.split('.')[1].trim()
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {trans(
                                      searchData.category.replace(' ', '-').toLowerCase(),
                                      searchData.category
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 group-hover:text-gwOrange">
                              {searchData.name}
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 font-primary absolute bottom-0 group-hover:text-gwOrange">
                              {country.currencySymbol}
                              {searchData.price}
                            </p>
                          </div>
                        </div>
                      </a>
                    )
                  })}
              </div>
              <div className="search-section-4 border-l border-borderGray pt-76 pb-20">
                {!props.isSearchResult &&
                  secondHalf_topSeller.length > 0 &&
                  secondHalf_topSeller.map((topSellerData, index) => {
                    return (
                      <a
                        key={index}
                        className="cursor-pointer group"
                        onClick={(e) => closeAndRedirect(e, `${topSellerData.slug}`)}
                      >
                        <div className="w-full inline-flex xl:py-5 border-b border-borderGray search-product-card items-center pl-1">
                          <img src={topSellerData.imageUrl} className="pc-image" />
                          <div className="product-info h-full  relative flex align-middle items-center pl-1">
                            <p className="text-sm leading-18 font-normal uppercase text-gray-600 absolute top-0 group-hover:text-gwOrange">
                              <span className="leading-18 font-primary uppercase">
                                {topSellerData.category.includes(' . ') ? (
                                  <>
                                    <span>
                                      {trans(
                                        topSellerData.category
                                          .split('.')[0]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        topSellerData.category.split('.')[0].trim()
                                      )}
                                    </span>
                                    <span> · </span>
                                    <span>
                                      {trans(
                                        topSellerData.category
                                          .split('.')[1]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        topSellerData.category.split('.')[1].trim()
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {trans(
                                      topSellerData.category.replace(' ', '-').toLowerCase(),
                                      topSellerData.category
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 group-hover:text-gwOrange">
                              {topSellerData.name}
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 font-primary absolute bottom-0 group-hover:text-gwOrange">
                              {country.currencySymbol}
                              {topSellerData.price}
                            </p>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                {props.isSearchResult &&
                  secondHalf_searchData.length > 0 &&
                  secondHalf_searchData.map((searchData, index) => {
                    return (
                      <a
                        key={index}
                        className="cursor-pointer group"
                        onClick={(e) => closeAndRedirect(e, `${searchData.slug}`)}
                      >
                        <div className="w-full inline-flex xl:py-5 border-b border-borderGray search-product-card items-center pl-1">
                          <img src={searchData.imageUrl} className="pc-image" />
                          <div className="product-info h-full  relative flex align-middle items-center pl-1">
                            <p className="text-sm leading-18 font-normal uppercase text-gray-600 absolute top-0 group-hover:text-gwOrange">
                              <span className="leading-18 font-primary uppercase">
                                {searchData.category.includes(' . ') ? (
                                  <>
                                    <span>
                                      {trans(
                                        searchData.category
                                          .split('.')[0]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        searchData.category.split('.')[0].trim()
                                      )}
                                    </span>
                                    <span> · </span>
                                    <span>
                                      {trans(
                                        searchData.category
                                          .split('.')[1]
                                          .trim()
                                          .replace(' ', '-')
                                          .toLowerCase(),
                                        searchData.category.split('.')[1].trim()
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {trans(
                                      searchData.category.replace(' ', '-').toLowerCase(),
                                      searchData.category
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 group-hover:text-gwOrange">
                              {searchData.name}
                            </p>
                            <p className="xl:text-md lg:text-smd xl:tracking-tight font-normal uppercase text-black leading-18 font-primary absolute bottom-0 group-hover:text-gwOrange">
                              {country.currencySymbol}
                              {searchData.price}
                            </p>
                          </div>
                        </div>
                      </a>
                    )
                  })}
              </div>
              <div className="search-section-5 border-l border-borderGray pt-17.5 pb-20"></div>
            </div>
          )}
          {props.emptySearchResult && (
            <div className="search-lower px-4 flex w-full h-full">
              <div className="empty-search-section-1 border-r  border-borderGray mr-2.5 flex justify-center pt-60r pb-20">
                <Icon
                  className={`align-middle inline-block h-full text-gray-700 fill-current`}
                  viewBox={`0 0 34 34`}
                  size={34}
                  icon="target"
                />
              </div>
              <div className="empty-search-section-2 border-borderGray mr-2.5 pt-60r pb-20 pl-10l">
                <div className="search-no-result w-full text-black">
                  <p className="text-spg leading-20">
                    {trans('search-could-not-find', 'We’re sorry, we couldn’t find results for')} “
                    {props.inputValue}”.
                  </p>
                  <p className="mt-3 text-base leading-20">
                    {trans('search-hints', 'Search Hints:')}
                  </p>
                  <ul className="mt-2 list-disc ml-5">
                    <li className=" text-base leading-20">
                      {trans('search-hints-line-1', 'Double check the spelling')}
                    </li>
                    <li className=" text-base leading-20">
                      {trans('search-hints-line-2', 'Use a more generic term (eg jacket, pants)')}
                    </li>
                    <li className=" text-base leading-20">
                      {trans(
                        'search-hints-line-3',
                        'Use the menu to find what you are looking for'
                      )}
                    </li>
                  </ul>

                  <p className="mt-3 text-base leading-20">
                    {trans('try-popular-searches', 'Try one of our popular searches:')}
                  </p>
                  <ul className="mt-5 list-none">
                    {props.popularSearches &&
                      props.popularSearches.map((item, key) => {
                        return (
                          <li className="mb-2" key={key}>
                            <Link
                              href={`/search?q=${item.searchQuery}`}
                              className="text-base leading-20"
                              onClick={(e) => closeAndRedirect(e, `/search?q=${item.searchQuery}`)}
                            >
                              {item.searchQuery}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
              <div className="empty-search-section-3 border-l border-borderGray mr-2.5 pt-60r pb-20 pl-10l">
                <div className="search-no-result-contact w-full text-black text-center">
                  <p className="text-25 leading-23">
                    {trans(
                      'search-couldnt-find-what-you-were-looking',
                      'Couldn’t Find What You Were Looking For?'
                    )}
                  </p>
                  <p className="mt-2.5 text-base leading-20">
                    {trans('here-to-help', 'We’re always here to help.')}
                  </p>
                  <a
                    href={`tel:${getRegionNumber()}`}
                    className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-5 text-smd leading-18"
                  >
                    {trans('call-us', 'Call us')}
                  </a>
                  <p className="mt-5 text-xsm leading-16 font-tertiary">{getRegionNumber()}</p>
                  <p className="text-gray-600 text-xsm leading-16 uppercase font-tertiary">
                    {trans('seach-call-us-toll-free', 'Call us toll-free')}
                  </p>
                  <p className="text-gray-600 text-xsm leading-16 uppercase font-tertiary">
                    {trans('search-mon-to-fri', 'MON-FRI 9AM - 6PM EST')}
                  </p>

                  <a
                    href={`mailto:${trans('customer-care-email', 'customercare@us.gorewear.com')}`}
                    className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-5 text-smd leading-18"
                  >
                    {trans('email-us', 'Email us')}
                  </a>
                  <p className="mt-5 text-xsm leading-16 uppercase font-tertiary">
                    {trans('customer-care-email', 'customercare@us.gorewear.com')}
                  </p>
                  <p className="text-gray-600 text-xsm leading-16 uppercase font-tertiary">
                    {trans('responds-in-24-hours', 'Responds in 24 hours')}
                  </p>

                  <a
                    href={supportUrl}
                    className="inline-flex items-center no-result-btn-blk px-5 uppercase mt-30.5 text-smd leading-18"
                  >
                    {trans('search-contact-form', 'Contact Form')}
                  </a>
                  <p className="text-gray-600 mt-5 text-xsm leading-16 uppercase font-tertiary">
                    {trans('responds-in-24-hours', 'Responds in 24 hours')}
                  </p>
                </div>
              </div>
              <div className="empty-search-section-4 border-l border-borderGray pt-60r pb-20"></div>
            </div>
          )}
        </div>
        <MobileSearchBox
          {...props}
          toggleCloseSearch={props.toggleCloseSearch}
          triggerSearch={props.triggerSearch}
          searchInput={props.searchInput}
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          clearText={props.clearText}
        />
      </div>
    </>
  )
}

export default SearchBox
