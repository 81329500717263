import { useEffect, useState } from 'react'
import Image from 'next/image'
import Head from 'next/head'

const defaultSizes = [
  `(max-width: 768px) 768px, (max-width: 1024px) 1024px, (max-width: 1535px) 1535px, 1920px`,
].join(',')

function handleContenfulImage({ src = '', width, quality }) {
  // Sometimes, height is passed directly in the src. We need to parse it out and build a new URL.
  const x = new URL(src)
  const customHeight = x.searchParams.get('h')

  const queryParams = ['fm=webp']

  if (customHeight) {
    queryParams.push(`h=${customHeight}`)
  }
  if (quality) {
    queryParams.push(`q=${quality}`)
  }

  return queryParams.length ? `${src}&${queryParams.join('&')}` : src
}

const handleBigCommerceImage = ({ src = '', width }) => {
  // Calculate closest NextJS Supported image size
  let customWidth = [256, 384, 640, 750, 828].reduce((a, b) => {
    return Math.abs(b - width) < Math.abs(a - width) ? b : a
  })

  if (!customWidth) {
    customWidth = width // Set a medium default value
  }

  let customSrc
  let urlPartials = src.split('original')
  if (urlPartials[1]) {
    customSrc = `${urlPartials[0]}${customWidth}w${urlPartials[1]}`
  } else {
    customSrc = src
  }

  if (process.env.NEXT_PUBLIC_IS_VERCEL === 'true') {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}&q=90&fm=webp`
  }

  return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}px&q=90&fm=webp`
}

const loader = (loadedProps) => {
  // Don't include image sizes smaller than 256px
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContenfulImage(loadedProps)
  if (src?.includes('bigcommerce')) return handleBigCommerceImage(loadedProps)
  return src
}

const HeroImage = ({
  src,
  srcMap,
  alt = '',
  height,
  width,
  className,
  objectFit = 'contain',
  loading = 'lazy',
  quality = 85,
  priority = false,
}) => {
  const [srcUrl, setSrcUrl] = useState({
    src: `${srcMap?.mobile || srcMap?.tablet || srcMap?.desktop}?w=768`,
    width: 0,
    loaded: false
  })

  const [customClass, setCustomClass] = useState(className)
  function handleImageChangeOnResize() {
    if (srcMap) {
      if (window.innerWidth < 768) {
        setSrcUrl({
          src: `${srcMap?.mobile}?w=${768}`,
          width: 768,
          loaded: true
        })
      } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
        setSrcUrl({
          src: `${srcMap?.tablet}?w=${1024}`,
          width: 1024,
          loaded: true
        })
      } else {
        setSrcUrl({
          src: `${srcMap?.desktop}?w=${1920}`,
          width: 1920,
          loaded: true
        })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleImageChangeOnResize)
    return () => {
      window.removeEventListener('resize', handleImageChangeOnResize)
    }
  }, [])

  useEffect(() => {
    handleImageChangeOnResize()

    // Check if background style has been set.
    if (!className || !className.includes('object-') || objectFit === 'cover') {
      setCustomClass(`${className} object-cover`)
    }

    if (objectFit === 'contain') {
      setCustomClass(`${className} object-contain`)
    }
  }, [])

  let imageProps = {
    className:`hero-base-image  w-full h-full ${customClass}`,
    src: srcMap ? `${srcUrl.src}` : `${src}`,
    sizes: defaultSizes,
    alt: alt,
    fill: height || width ? false : true,
    quality:quality,
    height:height,
    width:width
  }

  if (priority) {
    imageProps['priority'] = true
  } else {
    imageProps['loading'] = loading
  }

  return (
    <>
      <Image
        loader={loader}
        {...imageProps}
      />
      {
        srcMap?.desktop && srcMap?.desktop?.includes('ctfassets') && priority &&  (
          <Head>
            <link rel="preload" fetchpriority="high" as="image" href={srcMap?.desktop+'?w=1024&fm=webp&q=85'}></link>
          </Head>
        )
      }
      {
        !srcUrl?.loaded &&  <div className="hero-base-image-blur absolute inset-0 bg-white/30"></div>
      }
      <style jsx>{
        `
         .hero-base-image-blur {
          backdrop-filter: blur(40px);
         }
        `
      }</style>
    </>
  )
}

export default HeroImage
