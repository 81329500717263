import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import { isEmpty } from '@/lib/lodash'

const HelloBar = (props) => {
  const router = useRouter()
  const [refreshSwiper, setRefreshSwiper] = useState(false)
  const setRotation = (rotation) => {
    return `${rotation}000`
  }

  const [backgroundColor, setBackgroundColor] = useState(() => {
    if (props.messages && props.messages.length > 0) {
      return `#${props.messages[0].fields.backgroundColor}`
    }

    return '#e36f22'
  })

  const rotation = setRotation(props.rotationTime)

  useEffect(() => {
    SwiperCore.use([Autoplay, EffectFade])
    setRefreshSwiper(!refreshSwiper)
  }, [router.asPath])

  return (
    <>
      <div
        className="hello-bar uppercase text-xsm  min-height-7 h-7 leading-4 font-normal text-center font-tertiary text-black"
        style={{ backgroundColor: backgroundColor }}
      >
        <Swiper
          effect={'fade'}
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: rotation,
            disableOnInteraction: false,
          }}
          autoHeight={true}
          allowTouchMove={false}
          onSlideChangeTransitionStart={(swiper) => {
            setBackgroundColor(`#${props.messages[swiper.realIndex].fields.backgroundColor}`)
          }}
        >
          {props.messages.map((item, index) => (
            <SwiperSlide key={index}>
              {item != null && (
                <div
                  className="min-height-7 promo-text-line-height promo-py-7 w-full"
                  style={{
                    backgroundColor: `#${item.fields.backgroundColor}`,
                    color: `#${
                      isEmpty(item.fields.textColor) ? '#000000' : item.fields.textColor
                    }`,
                  }}
                >
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.fields.message}</ReactMarkdown>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style jsx>
        {`
          @media only screen and (max-width: 453px) {
            .min-height-7 {
              min-height: 40px;
            }
          }
          .promo-text-line-height {
            line-height: 16.34px;
          }
          .promo-py-7 {
            padding-top: 7px;
            padding-bottom: 7px;
          }
        `}
      </style>
    </>
  )

  return (
    <div className="hello-bar  border-gray-100">
      <div className="uppercase text-sm text-center font-tertiary">
        <Swiper
          effect={'fade'}
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: rotation,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
        >
          {props.messagesCollection.items.map((item, index) => (
            <SwiperSlide key={index}>
              {item != null && (
                <div
                  className="flex items-center justify-center w-full bg-gray-500 py-4 px-4 h-full"
                  style={{
                    backgroundColor: `#${item.backgroundColor}`,
                    color: `#${isEmpty(item.textColor) ? '#ffffff' : item.textColor}`,
                  }}
                >
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.message}</ReactMarkdown>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default HelloBar
