import {getSearchSpringKeys, getProductBySku as getProductBySkuBC} from '../product-helper';
import { getProduct, getAllProducts, getProductMetafields } from '@/redux/actions/productAction';
import slugify from 'slugify';
import { getRegion } from '../region-helper';

const apiCache = {};

const country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

async function fetchDataFromAPI(url) {
  // Check if the data is already cached
  if (apiCache[url]) {
    return apiCache[url];
  }

  // Fetch data from the API and store it in the cache
  const response = await fetch(url);
  const data = await response.json();
  apiCache[url] = data;
  return data;
}

async function getDataFromSS(sku) {
  const apiUrl = new URL(getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL);
  apiUrl.searchParams.append('q', sku)
  const cachedData = apiCache[apiUrl.href];

  if (cachedData) {
    // If data is already cached, return it immediately
    const returnData = cachedData.results[0]
    return returnData;
  }

  try {
    const data = await fetchDataFromAPI(apiUrl);

    // Use the data fetched from the API
    const returnData = data.results[0]
    return returnData;
  } catch (error) {
    // Handle error
  }
}

export const createProductSlug = (productTitle, sku) => {
  let productName
  let newProductName = productTitle
  if (newProductName) {
    newProductName = newProductName.toLowerCase()
    newProductName = newProductName.includes(':') ? newProductName.split(':')[1] : newProductName
    newProductName = newProductName.replace(/ä/g, 'ae')
    newProductName = newProductName.replace(/ö/g, 'oe')
    newProductName = newProductName.replace(/ü/g, 'ue')
    newProductName = newProductName.replace(/ß/g, 'ss')
    newProductName = newProductName.replace(/\//g, '-') // replace / with _
    newProductName = newProductName.replace(/√§/g, 'ae') // replace german symbol
    newProductName = newProductName.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // convert umlauts and accents to normal characters

    newProductName = newProductName.replace(/[^\w\s-]/gi, '') //replace non numeric characters
    productName = slugify(newProductName, { strict: true, remove: /[®™]/g })
  }
  let slug = (productName + '-' + sku).toLowerCase().replace(/\//g, '')

  return slug
}

export const getProductSlug = async (product, locale='en') => {
  const productTitle = await getProductTitle(product, locale)
  const productSlug = createProductSlug(productTitle, product.sku)
  return productSlug
}

export const getProductTitle = async (productSku, locale='en') => {
  const product = await getProductBySkuBC(productSku)
  return product.title
}

export const getProductTitleFromSS = (product, locale='en') => {
  let title = ''
  if(product.attributes) {
    title = product.attributes[`mfield_${country.localePrefix.toLowerCase()}_product_info_${country.localePrefix.toLowerCase()}_full_product_name`] || product.mappings.core.name || ''
  }
  else {
    title = product[`mfield_${country.localePrefix.toLowerCase()}_product_info_${country.localePrefix.toLowerCase()}_full_product_name`] || product['name'] || ''
  }
  title = title.replace(/[{}]|&quot;/g, '')
  title = title.replaceAll('"', '')
  return title
}

export const getProductSlugFromSSMeta = (productMeta, sku) => {
  if(country.region == 'US') {
    let slug = createProductSlug(productMeta['name'], sku)
    return slug
  }
  let title = productMeta[`mfield_${country.localePrefix.toLowerCase()}_product_info_${country.localePrefix.toLowerCase()}_full_product_name`] || productMeta['name'] || ''
  title = title.replace(/[{}]|&quot;/g, '')
  let slug = createProductSlug(title, sku)
  return slug
}

export const getProductCurrencySymbol = () => {
  return country.currencySymbol ?? ''
}

export const getProductUrl = async (productSku, locale='en') => {
  const product = await getProductBySkuBC(productSku)
  const returnData = {...product}
  return returnData.defaultSlug
}

// export const getProductVariants = (sku) => {
//   const cachedProduct = productsMin[sku]
//   return cachedProduct && cachedProduct.variants ? cachedProduct.variants : []
// }

// export const getProductImages = (sku) => {
//   const cachedProduct = productsMin[sku]
//   return cachedProduct && cachedProduct.images ? cachedProduct.images : null
// }

// export const getProductOptions = (sku) => {
//   const cachedProduct = productsMin[sku]
//   return cachedProduct.options
// }

export const getProductFromId = async (id) => {
  const product = await getProduct(id, {include: 'variants,options,images', include_fields: 'variants,options,images'})
  return product
}

export const getProductSlugPLP = (item, language) => {
  let title = item[`mfield_${language}_product_info_${language}_full_product_name`] || item['name'] || ''
  title = title.replace(/[{}]|&quot;/g, '')
  const productSlug = createProductSlug(title, item.sku)
  return productSlug
}

export const getProductBySku = async (params) => {
  const productData = await getAllProducts(params)
  return productData
}

export const getProductMetaData = async (id, sku) => {
  const productMetaFields = await getProductMetafields(id)
  let metaFieldsData = productMetaFields.data.find(meta => meta.key == `${country.localePrefix.toLowerCase()}_full_product_name`).value
  let metaTitle = metaFieldsData.replace(/[{}]|&quot;/g, '')
  let slug = createProductSlug(metaTitle, sku)
  return slug
}

export const getProductMetaTitleSlug = async (id, sku) => {
  const productMetaFields = await getProductMetafields(id)
  let metaFieldsData = productMetaFields.data.find(meta => meta.key == `${country.localePrefix.toLowerCase()}_full_product_name`).value
  let metaTitle = metaFieldsData.replace(/[{}]|&quot;/g, '')
  let slug = createProductSlug(metaTitle, sku)
  return {slug: slug, title: metaTitle}
}