export const trackUser = (isAuthenticated, profile) => {
  var _learnq = window._learnq || []
  console.debug('trackUser _learnq', _learnq)
  console.debug('trackUser isAuthenticated', isAuthenticated)
  console.debug('trackUser profile', profile)
  if (isAuthenticated) {
    _learnq.push([
      'identify',
      {
        $email: profile.userName,
        $first_name: profile.firstName,
        $last_name: profile.lastName,
      },
    ])
  }
}
