import { createStitches } from '@stitches/react'
import tailwindConfig from './tailwind.config'

const { screens } = tailwindConfig.theme
const media = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'].reduce(
  (mediaObj, key) => ({ ...mediaObj, [key]: `(min-width: ${screens[key]})` }),
  {}
)

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    media,
  })
