import { useState, useEffect } from 'react'
import countriesList from '@/config/countries.json'
import nextConfig from 'next/config'
import regionsList from '@/config/regions'
import { getRegion } from '@/lib/region-helper'
import axios from 'axios'
import { setLocaleToCookies } from '@/lib/locale-helper'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'

const { publicRuntimeConfig } = nextConfig()

const CountrySelectorOptions = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [currentCountry, setCurrentCountry] = useState({})
  const [visitedCountry, setVisitedCountry] = useState({})
  const router = useRouter()

  const checkUriLang = () => {
    let pathname = window.location.pathname.split('/').filter((n) => n)
    let region = ''
    let pagePath = ''
    if (pathname[0] == 'us') {
      region = 'us/en-us'
      pagePath = pathname.slice(2, pathname.length).join('/')
    } else {
      region = pathname[0]
      pagePath = pathname.slice(1, pathname.length).join('/')
    }
    const countryData = countriesList.find((item) => item.prefix == region)
    countryData ? (countryData.pagePath = pagePath) : ''

    setVisitedCountry(countryData)
    return countryData
  }

  const getClientLocation = async () => {
    const res = await axios.get(`${process.env.NEXT_PUBLIC_COMMERCE_URL}/user/country`)
    return res
  }

  // const { data, error, isLoading } = useQuery('randomFacts', getClientLocation)

  useEffect(() => {
    getClientLocation().then(data => {
      if (data) {
        let cCode = data.country
        let countryIs = regionsList.default.find((c) => c.countryCode == cCode)
        if (countryIs) {
          setCurrentCountry({
            countryName: countryIs.countryName,
            countryCode: countryIs.countryCode,
          })
          const countryFound = countriesList.filter((country) =>
            country.text.includes(countryIs.countryName)
          )
          setCurrentCountry((currentC) => {
            return {
              ...currentC,
              countryData: countryFound,
            }
          })
        }
      }
    })
  }, [])

  useEffect(() => {
    const countryCookie = checkUriLang()

    const countryLocale = typeof countryCookie !== 'undefined' ? countryCookie.locale : 'en_US'

    const newCountryList = props.countryList.filter((item) => item.locale != countryLocale)

    // const newCountryList = removeBlacklistedCountries(props.countryList.filter((item) => item.locale != countryLocale))

    setCountryList(newCountryList)
  }, [currentLanguage])

  useEffect(() => {
    if (typeof window != undefined) {
      setIsMobile(window.innerWidth >= 1024 ? false : true)
      window.addEventListener('resize', function (event) {
        setIsMobile(window.innerWidth >= 1024 ? false : true)
      })
    }
  }, [])

  useEffect(() => {
    if (visitedCountry && currentCountry?.countryData && currentCountry?.countryData.length > 0) {
      if (
        visitedCountry?.prefix != currentCountry?.countryData[0]?.prefix &&
        localStorage.getItem('navigatedomain') == 1 &&
        props.handleCountrySelectorModal
      ) {
        props.handleCountrySelectorModal(visitedCountry, currentCountry)
      }
    }
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  }, [visitedCountry, currentCountry])

  const setLanguage = async (e, item) => {
   
    
    setCurrentLanguage(item)
    localStorage.setItem('navigatedomain', 1)
    let pathname = window.location.pathname.split('/').filter((n) => n)
    let pagePath = ''
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    Cookies.remove('bc_cartId')
    Cookies.remove(`bc_cartId_${country.storeSource.toLowerCase()}`)
    let nextCountry = getRegion(item.prefix)
    let countryUrl = ''
    country?.storeSource !== nextCountry?.storeSource &&  Cookies.remove('ssCartProducts')
    if (process.env.NEXT_PUBLIC_EU_LEGACY_ON && process.env.NEXT_PUBLIC_EU_LEGACY_ON == 'true') {
      let prefixRegion = nextCountry.domain == 'en-uk' ? 'uk' : nextCountry.region.toLowerCase()

      let tempBaseUrl = 'https://www.gorewear.com'

      let tempCountrySelector = nextCountry.hreflang.replace('-', '_')

      let tempDomain = `${tempBaseUrl}/${prefixRegion}/${nextCountry.domain}/home?countryselector=${tempCountrySelector}`

      setLocaleToCookies(tempCountrySelector)

      countryUrl = tempDomain
    } else {
      let alternateLink = document.querySelector(`[hreflang="${nextCountry.hreflang}"]`)

      if (alternateLink && !alternateLink.href.includes('/account/')) {
        // redirect to home if account screens

        let href = alternateLink.href

        if (href?.endsWith('search') && router?.query?.q) {
          countryUrl = `${href}?q=${router?.query?.q}&countryselector=${item.locale}`
        } else {
          countryUrl = `${href}?countryselector=${item.locale}`
        }
      } else {
        let domain = nextCountry.domain == 'en-us' ? 'us/en-us' : nextCountry.domain

        countryUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${domain}/home?countryselector=${item.locale}`
      }

      setLocaleToCookies(item.locale)
    }

    localStorage.removeItem('user_checkout_locale')

    e.preventDefault()
    window.location = countryUrl
  }

  const displayCountryList = (countryList, perChunk = 4) => {
    const chunkedArray = countryList.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    let countryListItems = chunkedArray.map((lang, index) => {
      return (
        <div className="country-selector__options-group" key={index}>
          {lang.map((item) => {
            return (
              <div
                className="country-selector__option pt-5 border-r border-b border-gray-800 bg-black"
                key={item.text}
              >
                <a
                  className="flex px-9.5 country-selector-content cursor-pointer"
                  onClick={(e) => setLanguage(e, item)}
                >
                  <span className={`flag-icon ${item.flag}`}></span>
                  <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                    {item.text}
                  </span>
                </a>
              </div>
            )
          })}
        </div>
      )
    })

    return countryListItems
  }

  const austria_flag = countryList.find((item) => item.locale == 'de_AT')
  const belgium_flag_en = countryList.find((item) => item.locale == 'en_BE')
  const belgium_flag_fr = countryList.find((item) => item.locale == 'fr_BE')
  const denmark_flag = countryList.find((item) => item.locale == 'en_DK')
  const finland_flag = countryList.find((item) => item.locale == 'en_FI')
  const france_flag = countryList.find((item) => item.locale == 'fr_FR')
  const germany_flag = countryList.find((item) => item.locale == 'de_DE')
  const ireland_flag = countryList.find((item) => item.locale == 'en_IE')
  const italy_flag = countryList.find((item) => item.locale == 'it_IT')
  const luxembourg_flag = countryList.find((item) => item.locale == 'fr_LU')
  const netherlands_flag = countryList.find((item) => item.locale == 'en_NL')
  const norway_flag = countryList.find((item) => item.locale == 'en_NO')
  const portugal_flag = countryList.find((item) => item.locale == 'pt_PT')
  const spain_flag = countryList.find((item) => item.locale == 'es_ES')
  const swiz_flag_de = countryList.find((item) => item.locale == 'de_CH')
  const swiz_flag_fr = countryList.find((item) => item.locale == 'fr_CH')
  const swiz_flag_it = countryList.find((item) => item.locale == 'it_CH')
  const sweden_flag = countryList.find((item) => item.locale == 'en_SE')
  const uk_flag = countryList.find((item) => item.locale == 'en_GB')
  const us_flag = countryList.find((item) => item.locale == 'en_US')

  const setLinkUrl = (item) => {
    try {
      let countryUrl
      let nextCountry = getRegion(item.prefix)

      let alternateLink = document.querySelector(`[hreflang="${nextCountry.hreflang}"]`)

      if (alternateLink && !alternateLink.href.includes('/account/')) {
        // redirect to home if account screens

        let href = alternateLink.href

        if (href?.endsWith('search') && router?.query?.q) {
          countryUrl = `${href}?q=${router?.query?.q}&countryselector=${item.locale}`
        } else {
          countryUrl = `${href}?countryselector=${item.locale}`
        }
      } else {
        let domain = nextCountry.domain == 'en-us' ? 'us/en-us' : nextCountry.domain

        countryUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${domain}/home?countryselector=${item.locale}`
      }

      return countryUrl
    } catch (error) {
      console.log('setLinkUrl', error)
      return '/'
    }
  }

  return (
    <>
      <div
        className={`${props.className} ${props.show == false ? 'hidden' : ''}`}
        style={props.style}
      >
        {!isMobile && (
          <div className="country-selector__options-group flex text-gray-400 bg-gray-800 w-full">
            <div className="mr-2 ml-10 lg:ml-6 xl:ml-15 country-selector__option py-5 border-r border-l border-gray-700 bg-gray-800">
              {austria_flag && (
                <div className="country-block">
                  <a
                    href={setLinkUrl(austria_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, austria_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={austria_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${austria_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {austria_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {(belgium_flag_en ||  belgium_flag_fr )&& (
                <div className={`country-block`}>
                  <div className="flex">
                    <img
                      className={`flag-icon`}
                      alt={belgium_flag_en?.text || belgium_flag_fr?.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${
                        belgium_flag_en?.flag || belgium_flag_fr?.flag
                      }.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {' '}
                      {belgium_flag_en?.parent_country || belgium_flag_fr?.parent_country}
                    </span>
                  </div>
                  <div className="flex ml-4 mt-2.5">
                    {belgium_flag_en && (
                      <div className="inline-block px-9.5 ml-1">
                        <a
                          href={setLinkUrl(belgium_flag_en)}
                          className="inline-flex country-selector-content cursor-pointer"
                          onClick={(e) => setLanguage(e, belgium_flag_en)}
                        >
                          <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                            {belgium_flag_en.text}
                          </span>
                        </a>
                      </div>
                    )}
                    {belgium_flag_fr && (
                      <div className="inline-block px-9.5 ml-1">
                        <a
                          href={setLinkUrl(belgium_flag_fr)}
                          className="inline-flex country-selector-content cursor-pointer"
                          onClick={(e) => setLanguage(e, belgium_flag_fr)}
                        >
                          <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                            {belgium_flag_fr.text}
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {denmark_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(denmark_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, denmark_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={denmark_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${denmark_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {denmark_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {finland_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(finland_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, finland_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={finland_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${finland_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {finland_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {(!austria_flag ||
                !denmark_flag ||
                !finland_flag ||
                !belgium_flag_en ||
                !belgium_flag_fr) &&
                france_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(france_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, france_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={france_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${france_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {france_flag.text}
                      </span>
                    </a>
                  </div>
                )}
            </div>
            <div className="mr-2 country-selector__option py-5 border-r border-l border-gray-700 bg-gray-800">
              {austria_flag &&
                denmark_flag &&
                finland_flag &&
                belgium_flag_en &&
                belgium_flag_fr &&
                france_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(france_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, france_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={france_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${france_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {france_flag.text}
                      </span>
                    </a>
                  </div>
                )}
              {germany_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(germany_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, germany_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={germany_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${germany_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {germany_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {ireland_flag && (
                <div className="country-block">
                  <a
                    href={setLinkUrl(ireland_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, ireland_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={ireland_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${ireland_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {ireland_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {italy_flag && (
                <div className="country-block">
                  <a
                    href={setLinkUrl(italy_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, italy_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={italy_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${italy_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {italy_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {(!austria_flag ||
                !denmark_flag ||
                !finland_flag ||
                !belgium_flag_en ||
                !belgium_flag_fr ||
                !france_flag ||
                !germany_flag ||
                !italy_flag ||
                !ireland_flag) &&
                luxembourg_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(luxembourg_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, luxembourg_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={luxembourg_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${luxembourg_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {luxembourg_flag.text}
                      </span>
                    </a>
                  </div>
                )}
            </div>
            <div className="mr-2 country-selector__option py-5 border-r border-l border-gray-700 bg-gray-800">
              {austria_flag &&
                denmark_flag &&
                finland_flag &&
                belgium_flag_en &&
                belgium_flag_fr &&
                france_flag &&
                germany_flag &&
                italy_flag &&
                ireland_flag &&
                luxembourg_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(luxembourg_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, luxembourg_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={luxembourg_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${luxembourg_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {luxembourg_flag.text}
                      </span>
                    </a>
                  </div>
                )}
              {netherlands_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(netherlands_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, netherlands_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={netherlands_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${netherlands_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {netherlands_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {norway_flag && (
                <div className="country-block">
                  <a
                    href={setLinkUrl(norway_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, norway_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={norway_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${norway_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {norway_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {portugal_flag && (
                <div className="country-block">
                  <a
                    href={setLinkUrl(portugal_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, portugal_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={portugal_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${portugal_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {portugal_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {(!austria_flag ||
                !denmark_flag ||
                !finland_flag ||
                !belgium_flag_en ||
                !belgium_flag_fr ||
                !france_flag ||
                !germany_flag ||
                !italy_flag ||
                !ireland_flag ||
                !portugal_flag ||
                !norway_flag ||
                !netherlands_flag ||
                !luxembourg_flag) &&
                spain_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(spain_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, spain_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={spain_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${spain_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {spain_flag.text}
                      </span>
                    </a>
                  </div>
                )}
            </div>
            <div className="country-selector__option py-5 border-l border-gray-700 bg-gray-800">
              {austria_flag &&
                denmark_flag &&
                finland_flag &&
                belgium_flag_en &&
                belgium_flag_fr &&
                france_flag &&
                germany_flag &&
                italy_flag &&
                ireland_flag &&
                portugal_flag &&
                norway_flag &&
                netherlands_flag &&
                luxembourg_flag &&
                spain_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(spain_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, spain_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={spain_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${spain_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {spain_flag.text}
                      </span>
                    </a>
                  </div>
                )}
              {(swiz_flag_de || swiz_flag_fr || swiz_flag_it) && (
                <div className={`country-block`}>
                  <div className="flex">
                    <img
                      className={`flag-icon`}
                      alt={
                        (swiz_flag_de && swiz_flag_de.text) ||
                        (swiz_flag_fr && swiz_flag_fr.text) ||
                        (swiz_flag_it && swiz_flag_it.text)
                      }
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${
                        swiz_flag_de?.flag || swiz_flag_fr?.flag || swiz_flag_it?.flag
                      }.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {swiz_flag_de?.parent_country ||
                        swiz_flag_fr?.parent_country ||
                        swiz_flag_it?.parent_country}
                    </span>
                  </div>
                  <div className="flex ml-4 mt-2.5">
                    {swiz_flag_de && (
                      <div className="inline-block px-9.5 ml-1">
                        <a
                          href={setLinkUrl(swiz_flag_de)}
                          className="inline-flex country-selector-content cursor-pointer"
                          onClick={(e) => setLanguage(e, swiz_flag_de)}
                        >
                          <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                            {swiz_flag_de.text}
                          </span>
                        </a>
                      </div>
                    )}
                    {swiz_flag_fr && (
                      <div className="inline-block px-9.5 ml-1">
                        <a
                          href={setLinkUrl(swiz_flag_fr)}
                          className="inline-flex country-selector-content cursor-pointer"
                          onClick={(e) => setLanguage(e, swiz_flag_fr)}
                        >
                          <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                            {swiz_flag_fr.text}
                          </span>
                        </a>
                      </div>
                    )}
                    {swiz_flag_it && (
                      <div className="inline-block px-9.5 ml-1">
                        <a
                          href={setLinkUrl(swiz_flag_it)}
                          className="inline-flex country-selector-content cursor-pointer"
                          onClick={(e) => setLanguage(e, swiz_flag_it)}
                        >
                          <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                            {swiz_flag_it.text}
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {sweden_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(sweden_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, sweden_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={sweden_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${sweden_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {sweden_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {uk_flag && (
                <div className={`country-block`}>
                  <a
                    href={setLinkUrl(uk_flag)}
                    className="inline-flex country-selector-content cursor-pointer"
                    onClick={(e) => setLanguage(e, uk_flag)}
                  >
                    <img
                      className={`flag-icon`}
                      alt={uk_flag.text}
                      src={`${publicRuntimeConfig.basePath}/flags-svg/${uk_flag.flag}.svg`}
                    />
                    <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                      {uk_flag.text}
                    </span>
                  </a>
                </div>
              )}
              {(!austria_flag ||
                !denmark_flag ||
                !finland_flag ||
                !belgium_flag_en ||
                !belgium_flag_fr ||
                !france_flag ||
                !germany_flag ||
                !italy_flag ||
                !ireland_flag ||
                !portugal_flag ||
                !norway_flag ||
                !netherlands_flag ||
                !luxembourg_flag ||
                !uk_flag ||
                !sweden_flag ||
                !spain_flag ||
                !swiz_flag_de ||
                !swiz_flag_fr ||
                !swiz_flag_it) &&
                us_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(us_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, us_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={us_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${us_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {us_flag.text}
                      </span>
                    </a>
                  </div>
                )}
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <div className="for-tab country-selector__options-group flex text-gray-400 bg-gray-800">
              <div className="mr-2 ml-6 country-selector__option py-5 border-r border-l border-gray-700 bg-gray-800">
                {austria_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(austria_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, austria_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={austria_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${austria_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {austria_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(belgium_flag_en || belgium_flag_fr) && (
                  <div className={`country-block`}>
                    <div className="flex">
                      <img
                        className={`flag-icon`}
                        alt={
                          (belgium_flag_en && belgium_flag_en.text) ||
                          (belgium_flag_fr && belgium_flag_fr.text)
                        }
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${
                          belgium_flag_en?.flag || belgium_flag_fr?.flag
                        }.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {belgium_flag_en?.parent_country || belgium_flag_fr?.parent_country}
                      </span>
                    </div>
                    <div className="flex ml-4 mt-2.5">
                      {belgium_flag_en && (
                        <div className="inline-block px-9.5 ml-1">
                          <a
                            href={setLinkUrl(belgium_flag_en)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, belgium_flag_en)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {belgium_flag_en.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {belgium_flag_fr && (
                        <div className="inline-block px-9.5 ml-1">
                          <a
                            href={setLinkUrl(belgium_flag_fr)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, belgium_flag_fr)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {belgium_flag_fr.text}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {denmark_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(denmark_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, denmark_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={denmark_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${denmark_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {denmark_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {finland_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(finland_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, finland_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={finland_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${finland_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {finland_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {france_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(france_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, france_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={france_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${france_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {france_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {germany_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(germany_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, germany_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={germany_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${germany_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {germany_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(!germany_flag ||
                  !france_flag ||
                  !finland_flag ||
                  !denmark_flag ||
                  !austria_flag ||
                  !belgium_flag_fr ||
                  !belgium_flag_en) &&
                  ireland_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(ireland_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, ireland_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={ireland_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${ireland_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {ireland_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
              </div>
              <div className="mr-2 country-selector__option py-5 border-r border-l border-gray-700 bg-gray-800">
                {germany_flag &&
                  france_flag &&
                  finland_flag &&
                  denmark_flag &&
                  austria_flag &&
                  belgium_flag_fr &&
                  belgium_flag_en &&
                  ireland_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(ireland_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, ireland_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={ireland_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${ireland_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {ireland_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
                {italy_flag && (
                  <div className={`country-block`}>
                    <a
                      href={setLinkUrl(italy_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, italy_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={italy_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${italy_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {italy_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {luxembourg_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(luxembourg_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, luxembourg_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={luxembourg_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${luxembourg_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {luxembourg_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {netherlands_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(netherlands_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, netherlands_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={netherlands_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${netherlands_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {netherlands_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {norway_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(norway_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, norway_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={norway_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${norway_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {norway_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {portugal_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(portugal_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, portugal_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={portugal_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${portugal_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {portugal_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(!germany_flag ||
                  !france_flag ||
                  !finland_flag ||
                  !denmark_flag ||
                  !austria_flag ||
                  !belgium_flag_fr ||
                  !belgium_flag_en ||
                  !portugal_flag ||
                  !norway_flag ||
                  !netherlands_flag ||
                  !luxembourg_flag ||
                  !italy_flag ||
                  !ireland_flag) &&
                  spain_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(spain_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, spain_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={spain_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${spain_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {spain_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
              </div>
              <div className="country-selector__option py-5 border-l border-gray-700 bg-gray-800">
                {germany_flag &&
                  france_flag &&
                  finland_flag &&
                  denmark_flag &&
                  austria_flag &&
                  belgium_flag_fr &&
                  belgium_flag_en &&
                  portugal_flag &&
                  norway_flag &&
                  netherlands_flag &&
                  luxembourg_flag &&
                  italy_flag &&
                  ireland_flag &&
                  spain_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(spain_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, spain_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={spain_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${spain_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {spain_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
                {(swiz_flag_de || swiz_flag_fr || swiz_flag_it) && (
                  <div className="country-block">
                    <div className="flex">
                      <img
                        className={`flag-icon`}
                        alt={
                          (swiz_flag_de && swiz_flag_de.text) || (swiz_flag_fr && swiz_flag_fr.text)
                        }
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${
                          swiz_flag_de?.flag || swiz_flag_fr?.flag || swiz_flag_it?.flag
                        }.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {swiz_flag_de?.parent_country ||
                          swiz_flag_fr?.parent_country ||
                          swiz_flag_it?.parent_country}
                      </span>
                    </div>
                    <div className="flex ml-4 mt-2.5">
                      {swiz_flag_de && (
                        <div className="inline-block px-9.5 ml-1">
                          <a
                            href={setLinkUrl(swiz_flag_de)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_de)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_de.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {swiz_flag_fr && (
                        <div className="inline-block px-9.5 ml-1">
                          <a
                            href={setLinkUrl(swiz_flag_fr)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_fr)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_fr.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {swiz_flag_it && (
                        <div className="inline-block px-9.5 ml-1">
                          <a
                            href={setLinkUrl(swiz_flag_it)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_it)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_it.text}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {sweden_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(sweden_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, sweden_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={sweden_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${sweden_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {sweden_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {uk_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(uk_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, uk_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={uk_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${uk_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {uk_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(!germany_flag ||
                  !france_flag ||
                  !finland_flag ||
                  !denmark_flag ||
                  !austria_flag ||
                  !belgium_flag_fr ||
                  !belgium_flag_en ||
                  !portugal_flag ||
                  !norway_flag ||
                  !netherlands_flag ||
                  !luxembourg_flag ||
                  !italy_flag ||
                  !ireland_flag ||
                  !uk_flag ||
                  !sweden_flag ||
                  !spain_flag ||
                  !swiz_flag_de ||
                  !swiz_flag_fr ||
                  !swiz_flag_it) &&
                  us_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(us_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, us_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={us_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${us_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {us_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
              </div>
            </div>
            <div className="for-mob country-selector__options-group flex text-gray-400 bg-gray-800 pb-29">
              <div className="mr-2 md:ml-10 2xs:ml-5 country-selector__option py-5 border-r md:border-l border-gray-700 bg-gray-800">
                {austria_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(austria_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, austria_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={austria_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${austria_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {austria_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(belgium_flag_en || belgium_flag_fr) && (
                  <div className={`country-block`}>
                    <div className="flex">
                      <img
                        className={`flag-icon`}
                        alt={
                          (belgium_flag_en && belgium_flag_en.text) ||
                          (belgium_flag_fr && belgium_flag_fr.text)
                        }
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${
                          belgium_flag_en?.flag || belgium_flag_fr?.flag
                        }.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {belgium_flag_en?.parent_country || belgium_flag_fr?.parent_country}
                      </span>
                    </div>
                    <div className="md:flex ml-4">
                      {belgium_flag_en && (
                        <div className="md:inline-block mt-2.5 px-9.5 ml-2">
                          <a
                            href={setLinkUrl(belgium_flag_en)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, belgium_flag_en)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {belgium_flag_en.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {belgium_flag_fr && (
                        <div className="md:inline-block mt-2.5 mb-5 md:mb-0 px-9.5 ml-2">
                          <a
                            href={setLinkUrl(belgium_flag_fr)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, belgium_flag_fr)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {belgium_flag_fr.text}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {denmark_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(denmark_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, denmark_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={denmark_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${denmark_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {denmark_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {finland_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(finland_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, finland_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={finland_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${finland_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {finland_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {france_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(france_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, france_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={france_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${france_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {france_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {germany_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(germany_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, germany_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={germany_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${germany_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {germany_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {ireland_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(ireland_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, ireland_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={ireland_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${ireland_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {ireland_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {italy_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(italy_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, italy_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={italy_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${italy_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {italy_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(!germany_flag ||
                  !france_flag ||
                  !finland_flag ||
                  !denmark_flag ||
                  !austria_flag ||
                  !ireland_flag ||
                  !italy_flag ||
                  !belgium_flag_fr ||
                  !belgium_flag_en) &&
                  luxembourg_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(luxembourg_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, luxembourg_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={luxembourg_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${luxembourg_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {luxembourg_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
              </div>
              <div className="country-selector__option py-5 border-l border-gray-700 bg-gray-800">
                {germany_flag &&
                  france_flag &&
                  finland_flag &&
                  denmark_flag &&
                  austria_flag &&
                  ireland_flag &&
                  italy_flag &&
                  belgium_flag_fr &&
                  belgium_flag_en &&
                  luxembourg_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(luxembourg_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, luxembourg_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={luxembourg_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${luxembourg_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {luxembourg_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
                {netherlands_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(netherlands_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, netherlands_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={netherlands_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${netherlands_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {netherlands_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {norway_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(norway_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, norway_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={norway_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${norway_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {norway_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {portugal_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(portugal_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, portugal_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={portugal_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${portugal_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {portugal_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {spain_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(spain_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, spain_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={spain_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${spain_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {spain_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(swiz_flag_de || swiz_flag_fr || swiz_flag_it) && (
                  <div className="country-block">
                    <div className="flex">
                      <img
                        className={`flag-icon`}
                        alt={
                          (swiz_flag_de && swiz_flag_de.text) ||
                          (swiz_flag_fr && swiz_flag_fr.text) ||
                          (swiz_flag_it && swiz_flag_it.text)
                        }
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${
                          swiz_flag_de?.flag || swiz_flag_fr?.flag || swiz_flag_it?.flag
                        }.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {swiz_flag_de?.parent_country ||
                          swiz_flag_fr?.parent_country ||
                          swiz_flag_it?.parent_country}
                      </span>
                    </div>
                    <div className="md:flex ml-4">
                      {swiz_flag_de && (
                        <div className="md:inline-block mt-2.5 px-9.5 ml-2">
                          <a
                            href={setLinkUrl(swiz_flag_de)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_de)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_de.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {swiz_flag_fr && (
                        <div className="md:inline-block mt-2.5 px-9.5 ml-2">
                          <a
                            href={setLinkUrl(swiz_flag_fr)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_fr)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_fr.text}
                            </span>
                          </a>
                        </div>
                      )}
                      {swiz_flag_it && (
                        <div className="md:inline-block mt-2.5 mb-5 md:mb-0 px-9.5 ml-2">
                          <a
                            href={setLinkUrl(swiz_flag_it)}
                            className="inline-flex country-selector-content cursor-pointer"
                            onClick={(e) => setLanguage(e, swiz_flag_it)}
                          >
                            <span className="tracking-tightest text-gray-400 font-normal uppercase text-xsm leading-16r">
                              {swiz_flag_it.text}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {sweden_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(sweden_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, sweden_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={sweden_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${sweden_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {sweden_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {uk_flag && (
                  <div className="country-block">
                    <a
                      href={setLinkUrl(uk_flag)}
                      className="inline-flex country-selector-content cursor-pointer"
                      onClick={(e) => setLanguage(e, uk_flag)}
                    >
                      <img
                        className={`flag-icon`}
                        alt={uk_flag.text}
                        src={`${publicRuntimeConfig.basePath}/flags-svg/${uk_flag.flag}.svg`}
                      />
                      <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                        {uk_flag.text}
                      </span>
                    </a>
                  </div>
                )}
                {(!germany_flag ||
                  !france_flag ||
                  !finland_flag ||
                  !denmark_flag ||
                  !austria_flag ||
                  !ireland_flag ||
                  !italy_flag ||
                  !belgium_flag_fr ||
                  !belgium_flag_en ||
                  !uk_flag ||
                  !sweden_flag ||
                  !spain_flag ||
                  !portugal_flag ||
                  !norway_flag ||
                  !netherlands_flag ||
                  !luxembourg_flag ||
                  !swiz_flag_de ||
                  !swiz_flag_fr ||
                  !swiz_flag_it) &&
                  us_flag && (
                    <div className="country-block">
                      <a
                        href={setLinkUrl(us_flag)}
                        className="inline-flex country-selector-content cursor-pointer"
                        onClick={(e) => setLanguage(e, us_flag)}
                      >
                        <img
                          className={`flag-icon`}
                          alt={us_flag.text}
                          src={`${publicRuntimeConfig.basePath}/flags-svg/${us_flag.flag}.svg`}
                        />
                        <span className="tracking-tightest text-gray-400 ml-2 font-normal uppercase text-xsm leading-16r">
                          {us_flag.text}
                        </span>
                      </a>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CountrySelectorOptions