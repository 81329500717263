import { useEffect, useRef } from 'react'
import { applyProDiscount, updateCartShippingLocation } from '@/redux/actions/cartAction'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupNameInfo, allowGroupDiscount } from '@/lib/product-helper'
// import { removeBlacklistedCountries } from '@/lib/region-helper'

const CountryCurrencySelect = (props) => {

  const dispatch = useDispatch()
  const { membership } = useSelector((state) => state.userReducer)
  const { customerGroup } = membership
  const groupNameInfo = customerGroup && customerGroup.name ? getGroupNameInfo(customerGroup.name) : null

  const wrapperRef = useRef(null)

  const selectCountry = async (e, item) => {
    if (typeof props?.setCountry === 'function') {
      props?.setCountry(e, item)
    }
    await dispatch(updateCartShippingLocation(item))
    if (allowGroupDiscount(groupNameInfo)) {
      await dispatch(applyProDiscount())
    }
  }

  let countryList = props?.countryList?.filter(country => country.region != 'US' && country.useFlag)

  // let countryList = removeBlacklistedCountries(props?.countryList?.filter(country => country.region != 'US' && country.useFlag))

  const handleOutsideClick = (e) => {

    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      props.setShow(false)
    }
  }

  useEffect(() => {

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [wrapperRef])

  return (
    <>
      <div ref={wrapperRef} className={`cart-country-selector bg-white ${props.className} ${props.show == false ? 'hidden' : ''}`} style={props.style} >
        <div className="grid">
          {
            countryList.map((country, index) => {
              if (country?.domain !== props?.selectedCountry?.domain) {
                return <div key={'country_' + index} className="country-block">
                  <a className="flex items-center country-selector-content cursor-pointer"
                    onClick={(e) => selectCountry(e, country)} >
                    <span className={`country-block__flag flag-icon ${country?.flag}`}></span>
                    <span className="country-block__name ml-2 font-tertiary uppercase">{country?.countryName}</span>
                  </a>
                </div>
              }
            })
          }
        </div>
      </div>
      <style jsx>{
        `
          .cart-country-selector  {
            box-shadow: 0px 2px 3px rgba(52, 52, 52, 0.4);
            left: 50%;
            width: 348px;
            padding: 10px 20px;
            transform: translateX(-50%);
          }

          .cart-country-selector .grid {
            grid-auto-flow: column;
            grid-template-rows: repeat(${Math.floor(countryList.length / 2)}, auto);
          }

          .country-block {
            font-size: 13px;
            line-height: 16px;
            min-height: auto;
            padding: 15px 0;
            width: 150px;
          }

          .country-block__name {
            padding-top: 1px;
          }

          .country-block__flag {
            height: 16px;
            width: 20px;
          }
        `
      }</style>
    </>
  )
}

export default CountryCurrencySelect;
