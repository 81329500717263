import queryString from 'query-string'
import randomstring from 'randomstring'
import pkceChallenge from 'pkce-challenge'
import { sleep,getCountrySelectorData } from '@/lib/helpers'
// import countriesList from '@/config/countries.json'


const SignInBtn = ({ text = 'sign in', linkClass = '', linkId = '' }) => {
  const onSignIn = async () => {
    try {
      const state = randomstring.generate()
      let challenge = pkceChallenge()
      const savedCountry = getCountrySelectorData();
      const configAuth = {
        // tester_id: 'gore_na_hiren_dev_1',
        // server: 'na.ssoauthts.goreweardev.com',
        redirect_uri: `${process.env.NEXT_PUBLIC_BASE_URL}/${savedCountry?.prefix}/auth-callback`,
        // email_domain: null,
        // default_password: null,
        client_id: process.env.NEXT_PUBLIC_PING_LOGIN_CLIENT,
        code_challenge: challenge.code_challenge,
        code_challenge_method: 'S256',
        ui_locales: savedCountry?.locale,
        response_type: 'code',
        scope: 'openid profile',
        state: state,
      }
      const pkceData = {
        code_challenge: challenge.code_challenge,
        code_verifier: challenge.code_verifier,
        state: state,
      }
      localStorage.setItem('gore-na:pkceData', JSON.stringify(pkceData))
      console.debug('configAuth', configAuth)
      await sleep(1000)
      debugger
      let query = queryString.stringify(configAuth, {
        sort: false,
        encode: false,
      })
      let AuthRedirect = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/as/authorization.oauth2?${query}`
      if (0) {
        window.open(AuthRedirect, '_blank').focus()
      } else {
        window.location.replace(AuthRedirect)
      }
    } catch (err) {
      // Handle Error Here
      // console.log('onSignOut errObj', err)
    }
  }
  return (
    <>
      <button
        data-href="#"
        className={`${linkClass} cursor-pointer flex justify-center items-center`}
        id={`${linkId}`}
        onClick={(e) => {
          e.preventDefault()
          onSignIn()
        }}
      >
        {text}
      </button>
    </>
  )
}

export default SignInBtn
