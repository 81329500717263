import Link from 'next/link'
import Icon from '@/components/icon/icon'
import MegaMenu from '@/components/common/mega-menu'
import MobileMenu from '@/components/common/mobile-menu'
import SearchBox from '@/components/search/search-box'
import { Image } from '@/components/image'
import { showContentOverlay, addForwardSlash } from '@/lib/helpers'
import { navClickGTM } from '@/lib/gtm'

import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'

const initiateSearch = async (data) => {
  toggleSearchBox()
  router.push(`/search?q=${data}`)
}

const handleHeaderCategoryClick = (menu) => {
  // GTM
  navClickGTM(menu?.enUrl, menu?.enTitle, menu?.enTitle, 'header')
}

const toggleMobileMenu = (e) => {
  const mobileMenu =
    e.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement
      .childNodes[1]
  const mobileMenuOpen = !mobileMenu?.classList.contains('slide-in')
  const mobileMenuLinks = mobileMenu?.childNodes?.[1]?.getElementsByTagName('a')

  if (mobileMenuOpen) {
    for (let i = 0; i < mobileMenuLinks?.length; i++) {
      mobileMenuLinks?.[i]?.setAttribute('tabIndex', '0')
    }
  } else {
    for (let i = 0; i < mobileMenuLinks?.length; i++) {
      mobileMenuLinks?.[i]?.setAttribute('tabIndex', '-1')
    }
  }
}

const ScrollableHeader = ({
  headerClass = '',
  clearText,
  topSellerData,
  searchData,
  popularSearches,
  clearAllData,
  emptySearchResult,
  searchInputField,
  setIsSearchOpened,
  showCountrySelector,
  searchRef,
  searchInput,
  handleMenuClick,
  inputValue,
  handleSearchChange,
  bgOverlay,
  searchSuggestion,
  setSearchSuggestion,
  alternateSearchSuggestion,
  setAlternateSearchSuggestion,
  isSearchResult,
  publicRuntimeConfig,
  isSearchOpened,
  isAuthenticated,
  isOpened,
  mobileMenuActive,
  searchTerm,
  allList,
  total_item,
  headerProps,
  toggleSearchBox,
  setSearchTerm,
  enterPressed,
  openWishlistPage,
  toggleCloseSearch,
  setInputValue,
  closeMenu,
  setIsSearchResult,
  setIsOpened,
  setShowMegaMenu,
  triggerSearch,
  isMobile,
  showMegaMenu,
}) => {
  useEffect(() => {
    isOpened ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
  }, [isOpened])

  const [matchedID, setMatchedId] = useState()

  return (
    <>
      <div className="topbar-placeholder-div" />
      <div
        id="scrollable-header"
        ref={searchRef}
        className={`${headerClass} topbar-animation-scroll text-xs z-10 ${
          bgOverlay ? 'bg-overlay-event' : ''
        } bg-gray-900`}
        style={{ height: '56px' }}
      >
        <div className={`flex 2xs:justify-center 2xs:px-6 lg:justify-start lg:px-0`}>
          <Link href="/" className="logo flex flex-shrink-0 absolute z-40">
            <Image
              src={`${publicRuntimeConfig.basePath}/images/gorewear-logo.svg`}
              className="w-full"
              alt="Gore Wear"
            />
          </Link>
          <div className="site-navigation-wrap md:flex lg:hidden flex-1 justify-start items-center bg-gray-900">
            <ul className="flex flex-shrink-0 items-center h-full list-none">
              <li
                className={`${
                  isSearchOpened ? 'search-icon-mobile-xs h-full' : ''
                } search-li md:ml-5 order-2 cursor-pointer mobile-menu-li-mob`}
                onClick={() => {
                  if (isOpened) {
                    setIsOpened(!isOpened)
                    mobileMenuActive.current = !isOpened
                  }
                  toggleSearchBox()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 1.75C4.77208 1.75 1.75 4.77208 1.75 8.5C1.75 12.2279 4.77208 15.25 8.5 15.25C12.2279 15.25 15.25 12.2279 15.25 8.5C15.25 4.77208 12.2279 1.75 8.5 1.75ZM0.25 8.5C0.25 3.94365 3.94365 0.25 8.5 0.25C13.0563 0.25 16.75 3.94365 16.75 8.5C16.75 10.5081 16.0325 12.3487 14.84 13.7793L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L13.7793 14.84C12.3487 16.0325 10.5081 16.75 8.5 16.75C3.94365 16.75 0.25 13.0563 0.25 8.5Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li
                className={`${
                  isSearchOpened ? 'invisible' : 'visible mobile-menu-li'
                } xxs:mr-4 md:mr-5 order-1 cursor-pointer`}
              >
                <button
                  name="mobile-menu"
                  className="outline-none focus:outline-none"
                  aria-label="Mobile Menu"
                  onClick={(e) => {
                    setIsOpened(!isOpened)
                    toggleMobileMenu(e)
                    mobileMenuActive.current = !isOpened
                  }}
                >
                  {isOpened ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.469684 0.46967C0.762577 0.176777 1.23745 0.176777 1.53034 0.46967L8.00001 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06067 8L15.5304 14.4697C15.8232 14.7626 15.8232 15.2374 15.5304 15.5303C15.2375 15.8232 14.7626 15.8232 14.4697 15.5303L8.00001 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93935 8L0.469684 1.53033C0.176791 1.23744 0.176791 0.762563 0.469684 0.46967Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H17C17.4142 0.25 17.75 0.585786 17.75 1C17.75 1.41421 17.4142 1.75 17 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM0.25 6C0.25 5.58579 0.585786 5.25 1 5.25H17C17.4142 5.25 17.75 5.58579 17.75 6C17.75 6.41421 17.4142 6.75 17 6.75H1C0.585786 6.75 0.25 6.41421 0.25 6ZM0.25 11C0.25 10.5858 0.585786 10.25 1 10.25H17C17.4142 10.25 17.75 10.5858 17.75 11C17.75 11.4142 17.4142 11.75 17 11.75H1C0.585786 11.75 0.25 11.4142 0.25 11Z"
                          fill="white"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </li>
            </ul>
          </div>
          <div className="site-navigation-wrap flex flex-1 lg:pr-6 xl:pr-15 justify-end items-center bg-gray-900">
            {headerProps?.menu?.menuItems?.length && !isMobile && (
              <ul className={`${isSearchOpened ? 'hidden' : 'site-navigation'}  h-full`}>
                {headerProps?.menu?.menuItems.map((item, key) => {
                  if (!isEmpty(item) && item.fields && !item?.fields?.mobileOnly) {
                    return (
                      <li
                        className="site-navigation__responsive-fix site-navigation__link mr-45 xl:mr-60r flex items-center h-full group static z-30"
                        key={key}
                        onMouseOver={() => {
                          showContentOverlay()
                          let customCloseMenu = sessionStorage.getItem('customCloseMenu')
                          if (!customCloseMenu || customCloseMenu === 'false') {
                            setShowMegaMenu(true)
                            setMatchedId(item?.sys?.id)
                          }
                          headerProps.setShowOptions && headerProps.setShowOptions(false)
                          setIsSearchOpened(false)
                        }}
                        onMouseOut={() => {
                          closeMenu()
                          sessionStorage.setItem('customCloseMenu', false)
                        }}
                        onClick={(e) => {
                          let shouldClose = e.target.classList.contains(
                            'site-navigation__link-item'
                          )
                          sessionStorage.setItem('customCloseMenu', true)
                          if (shouldClose) {
                            closeMenu()
                            setSearchSuggestion(null)
                            setAlternateSearchSuggestion(null)
                            setIsSearchResult(false)
                            setInputValue('')
                          }
                        }}
                      >
                        <Link
                          href={addForwardSlash(item.fields.url)}
                          className={`site-navigation__link-item flex relative items-center font-normal text-white leading-18 uppercase text-sm ${
                            showMegaMenu && matchedID === item?.sys?.id ? 'border-orange-500 border-b-4 mt-1' : ''
                          }`}
                          onClick={(e) => {
                            handleHeaderCategoryClick(item?.fields)
                          }}
                          prefetch={false}
                        >
                          {item.fields.title}
                        </Link>
                        <div className={'megamenu-wrap'}>
                          <MegaMenu
                            url={item.fields.url}
                            submenuCollection={item.fields.submenu}
                            closeMenu={closeMenu}
                            setMounted={headerProps.setMounted}
                            setShowOptions={headerProps.setShowOptions}
                            setIsSearchResult={setIsSearchResult}
                            isSearchResult={isSearchResult}
                            alternateSearchSuggestion={alternateSearchSuggestion}
                            setAlternateSearchSuggestion={setAlternateSearchSuggestion}
                            setSearchSuggestion={setSearchSuggestion}
                            searchSuggestion={searchSuggestion}
                            handleSearchChange={handleSearchChange}
                            enterPressed={enterPressed}
                            inputValue={inputValue}
                            isWeb={true}
                            handleMenuClick={handleMenuClick}
                            parentTitle={item?.fields?.enTitle}
                          />
                        </div>
                      </li>
                    )
                  }
                })}
              </ul>
            )}
            <ul className={`flex flex-shrink-0 items-center h-full list-none`}>
              <li
                className={`relative hidden search-li justify-center items-center cursor-pointer px-3 pr-4 rounded-full bg-gray-700 lg:inline-flex lg-search-icon search-show ${
                  isSearchOpened ? 'opened-search' : 'closed-search'
                } `}
                onClick={() => toggleSearchBox()}
              >
                {!isSearchOpened && <div className="absolute inset-0 search-click-overlay"></div>}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  onClick={triggerSearch}
                  className="inline"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.75 2.125C4.6434 2.125 2.125 4.6434 2.125 7.75C2.125 10.8566 4.6434 13.375 7.75 13.375C10.8566 13.375 13.375 10.8566 13.375 7.75C13.375 4.6434 10.8566 2.125 7.75 2.125ZM0.875 7.75C0.875 3.95304 3.95304 0.875 7.75 0.875C11.547 0.875 14.625 3.95304 14.625 7.75C14.625 9.42344 14.0271 10.9572 13.0333 12.1494L16.1086 15.2247C16.3527 15.4688 16.3527 15.8645 16.1086 16.1086C15.8645 16.3527 15.4688 16.3527 15.2247 16.1086L12.1494 13.0333C10.9572 14.0271 9.42344 14.625 7.75 14.625C3.95304 14.625 0.875 11.547 0.875 7.75Z"
                    fill="black"
                  />
                </svg>
                <input
                  type="text"
                  ref={searchInput}
                  value={searchTerm}
                  id="main-search-input"
                  name="search"
                  placeholder="Search"
                  aria-label="search"
                  className="w-full ml-3 outline-none text-base md:text-sm leading-18 cursor-pointer"
                  onChange={(event) => setSearchTerm(event.target.value)}
                  onKeyPress={(event) => enterPressed(event)}
                  disabled={isSearchOpened ? false : true}
                />
                {searchTerm && searchTerm.length > 1 && (
                  <Icon
                    className={`cursor-pointer text-black fill-current clear-text`}
                    viewBox={`0 0 28 28`}
                    size={28}
                    icon="clear"
                    onClick={clearText}
                  />
                )}
                {/* {
                            isSearchOpened ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.469684 0.46967C0.762577 0.176777 1.23745 0.176777 1.53034 0.46967L8.00001 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06067 8L15.5304 14.4697C15.8232 14.7626 15.8232 15.2374 15.5304 15.5303C15.2375 15.8232 14.7626 15.8232 14.4697 15.5303L8.00001 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93935 8L0.469684 1.53033C0.176791 1.23744 0.176791 0.762563 0.469684 0.46967Z" fill="white"/>
                              </svg>
                            ) : (
                              <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.75 2.125C4.6434 2.125 2.125 4.6434 2.125 7.75C2.125 10.8566 4.6434 13.375 7.75 13.375C10.8566 13.375 13.375 10.8566 13.375 7.75C13.375 4.6434 10.8566 2.125 7.75 2.125ZM0.875 7.75C0.875 3.95304 3.95304 0.875 7.75 0.875C11.547 0.875 14.625 3.95304 14.625 7.75C14.625 9.42344 14.0271 10.9572 13.0333 12.1494L16.1086 15.2247C16.3527 15.4688 16.3527 15.8645 16.1086 16.1086C15.8645 16.3527 15.4688 16.3527 15.2247 16.1086L12.1494 13.0333C10.9572 14.0271 9.42344 14.625 7.75 14.625C3.95304 14.625 0.875 11.547 0.875 7.75Z" fill="black"/>
                              </svg>
                              </>
                            )
                          }
                      <span className="text-sm text-gray-900 pt-2 pb-2 pl-2">Search</span> */}
              </li>

              <li
                className={`hidden lg:inline-flex mr-2 md:mr-5 cursor-pointer order-1 ${
                  isSearchOpened ? 'search-icon-mobile-md h-full' : 'search-li mobile-menu-li-tab'
                }`}
                onClick={() => toggleSearchBox()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 1.75C4.77208 1.75 1.75 4.77208 1.75 8.5C1.75 12.2279 4.77208 15.25 8.5 15.25C12.2279 15.25 15.25 12.2279 15.25 8.5C15.25 4.77208 12.2279 1.75 8.5 1.75ZM0.25 8.5C0.25 3.94365 3.94365 0.25 8.5 0.25C13.0563 0.25 16.75 3.94365 16.75 8.5C16.75 10.5081 16.0325 12.3487 14.84 13.7793L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L13.7793 14.84C12.3487 16.0325 10.5081 16.75 8.5 16.75C3.94365 16.75 0.25 13.0563 0.25 8.5Z"
                    fill="white"
                  />
                </svg>
              </li>

              {/* {isAuthenticated && ( */}
              <li
                className={`${
                  isSearchOpened ? 'invisible' : 'visible whishlist-li'
                } cursor-pointer order-2 xxs:mr-4 md:mr-18.5`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  onClick={async () => openWishlistPage(isAuthenticated)}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.54569 1.75C3.47486 1.75 1.75 3.49882 1.75 5.71405C1.75 6.774 2.15628 7.78529 2.87128 8.52678L10 15.9195L17.1287 8.52678C17.8437 7.78529 18.25 6.774 18.25 5.71405C18.25 3.49882 16.5251 1.75 14.4543 1.75C13.4566 1.75 12.494 2.16076 11.7799 2.90131L10.5399 4.18727C10.3985 4.33386 10.2036 4.41667 10 4.41667C9.79636 4.41667 9.60147 4.33386 9.46012 4.18727L8.22009 2.90131C7.50598 2.16076 6.54338 1.75 5.54569 1.75ZM0.25 5.71405C0.25 2.72228 2.59548 0.25 5.54569 0.25C6.95917 0.25 8.309 0.832556 9.29986 1.86011L10 2.58619L10.7001 1.86011L11.225 2.36619L10.7001 1.86011C11.691 0.832555 13.0408 0.25 14.4543 0.25C17.4045 0.25 19.75 2.72228 19.75 5.71405C19.75 7.15458 19.1984 8.54135 18.2085 9.56798L10.5399 17.5206C10.3985 17.6672 10.2036 17.75 10 17.75C9.79636 17.75 9.60147 17.6672 9.46012 17.5206L1.79152 9.56798C0.801554 8.54135 0.25 7.15458 0.25 5.71405Z"
                    fill="white"
                  />
                </svg>
                {allList.length > 0 && (
                  <span className="cartCount font-bold cart-dot">{allList.length}</span>
                )}
              </li>
              {/* )} */}
              <li
                className={`${
                  isSearchOpened ? 'invisible' : 'visible cartCount-li'
                } cursor-pointer order-3`}
              >
                <a aria-label="Cart" href={`${publicRuntimeConfig.basePath}/checkout-cart`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H3.5C3.87324 0.25 4.18968 0.524447 4.24246 0.893934L4.43619 2.25H20C20.2411 2.25 20.4675 2.36589 20.6084 2.56147C20.7494 2.75704 20.7878 3.00846 20.7115 3.23717L17.7115 12.2372C17.6094 12.5434 17.3228 12.75 17 12.75H5.93619L6.11981 14.0354C6.1374 14.1585 6.24288 14.25 6.36729 14.25H16C17.5188 14.25 18.75 15.4812 18.75 17C18.75 18.5188 17.5188 19.75 16 19.75C14.4812 19.75 13.25 18.5188 13.25 17C13.25 16.5499 13.3581 16.125 13.5499 15.75H10.4501C10.6419 16.125 10.75 16.5499 10.75 17C10.75 18.5188 9.51878 19.75 8 19.75C6.48122 19.75 5.25 18.5188 5.25 17C5.25 16.485 5.39157 16.0031 5.63791 15.591C5.11152 15.3493 4.7214 14.8531 4.63488 14.2475L2.84953 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM8 15.75C7.30964 15.75 6.75 16.3096 6.75 17C6.75 17.6904 7.30964 18.25 8 18.25C8.69036 18.25 9.25 17.6904 9.25 17C9.25 16.3096 8.69036 15.75 8 15.75ZM16 15.75C15.3096 15.75 14.75 16.3096 14.75 17C14.75 17.6904 15.3096 18.25 16 18.25C16.6904 18.25 17.25 17.6904 17.25 17C17.25 16.3096 16.6904 15.75 16 15.75ZM5.7219 11.25H16.4594L18.9594 3.75H4.65047L5.7219 11.25Z"
                      fill="white"
                    />
                  </svg>
                </a>
                {parseInt(total_item) > 0 && (
                  <span className="cartCount font-bold cart-dot">{total_item}</span>
                )}
              </li>
              <li
                className={`${
                  isSearchOpened ? 'close-search-li' : 'hidden'
                }  cursor-pointer order-3`}
              >
                <div className="absolute search-block-close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    onClick={toggleCloseSearch}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.469684 0.46967C0.762577 0.176777 1.23745 0.176777 1.53034 0.46967L8.00001 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06067 8L15.5304 14.4697C15.8232 14.7626 15.8232 15.2374 15.5304 15.5303C15.2375 15.8232 14.7626 15.8232 14.4697 15.5303L8.00001 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93935 8L0.469684 1.53033C0.176791 1.23744 0.176791 0.762563 0.469684 0.46967Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {isMobile && (
          <MobileMenu
            isOpened={isOpened}
            setShowAuth={headerProps.setShowAuth}
            setIsOpened={setIsOpened}
            menu={headerProps?.menu?.menuItems}
            menuDefault={headerProps?.menu?.menuItemsDefault}
            showCountrySelector={showCountrySelector}
            setMounted={headerProps.setMounted}
            setIsSearchResult={setIsSearchResult}
            isSearchResult={isSearchResult}
            alternateSearchSuggestion={alternateSearchSuggestion}
            setAlternateSearchSuggestion={setAlternateSearchSuggestion}
            setSearchSuggestion={setSearchSuggestion}
            searchSuggestion={searchSuggestion}
            handleSearchChange={handleSearchChange}
            enterPressed={enterPressed}
            inputValue={inputValue}
            footer={headerProps.footer}
          />
        )}

        {/* <OutsideAlerter setIsSearchOpened={setIsSearchOpened} isSearchOpened={isSearchOpened}> */}
        <SearchBox
          setIsSearchOpened={setIsSearchOpened}
          isOpened={isSearchOpened}
          setIsSearchResult={setIsSearchResult}
          isSearchResult={isSearchResult}
          alternateSearchSuggestion={alternateSearchSuggestion}
          setAlternateSearchSuggestion={setAlternateSearchSuggestion}
          setSearchSuggestion={setSearchSuggestion}
          searchSuggestion={searchSuggestion}
          handleSearchChange={handleSearchChange}
          enterPressed={enterPressed}
          refr={searchInputField}
          inputValue={inputValue}
          clearAllData={clearAllData}
          initiateSearch={initiateSearch}
          popularSearches={popularSearches}
          emptySearchResult={emptySearchResult}
          searchData={searchData}
          topSellerData={topSellerData}
          toggleSearchBox={toggleSearchBox}
          toggleCloseSearch={toggleCloseSearch}
          triggerSearch={triggerSearch}
          searchInput={searchInput}
          clearText={clearText}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {/* </OutsideAlerter> */}
      </div>
    </>
  )
}

export default ScrollableHeader
