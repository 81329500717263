import Cookies from 'js-cookie'
import {getSearchSpringKeys} from '@/lib/product-helper'

let _isuid = Cookies.get('_isuid')
export function productView(product, customerId) {
    try{
        var product_code = product.sku;
        IntelliSuggest.init({siteId:getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID, context:'Product/' + product_code, seed:[product_code]});
        IntelliSuggest.setShopperId(parseInt(customerId) == 0 ? '' : customerId);
        IntelliSuggest.viewItem({sku:product_code});
    } catch(err) {
        console.error(err)
    }
}

export function cartView(sku, cart, customerId) {
    try {
        IntelliSuggest.init({siteId:getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID, context:'Basket', seed:sku});
        IntelliSuggest.setShopperId(parseInt(customerId) == 0 ? '' : customerId);
        cart?.physical_items?.map(item => {
            let calculatedPrice = item.extended_sale_price / item.quantity
            IntelliSuggest.haveItem({sku:item.product.sku, qty:item.quantity, price:calculatedPrice, childSku: item.sku});
        })
        IntelliSuggest.inBasket({});
    } catch (error) {
        console.error(error)
    }
}

export function intellisuggestTrackClick(element, data, signature, customerId) {
    var escapeFn = encodeURIComponent || escape; 
    if(!_isuid) {
        Cookies.set('_isuid', customerId)
    }
    if(document.images) {
        if ('https:' == location.protocol) {
            var api_url = `https://${getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/`;
        } else {
            var api_url = `http://${getSearchSpringKeys().NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/`;
        }
        var imgTag = new Image;
        imgTag.src= api_url+'track/track.json?d='+data+'&s='+signature+'&u='+escapeFn(element)+'&r='+escapeFn(document.referrer);
      }
  
      return true;
  }
