import Modal from 'react-modal'
import { updateLoginModal } from '@/redux/actions/uiAction'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@/components/icon/icon'
import Link from 'next/link'
import SignInBtn from '@/components/common/sign-in-btn'
import { trans } from '@/lib/locale-helper'

const LoginModal = (props) => {
  const dispatch = useDispatch()
  const { loginModal } = useSelector((state) => state.uiReducer)

  return (
    <Modal
      closeTimeoutMS={300}
      isOpen={loginModal}
      className="modal-login"
      overlayClassName="modal__overlay"
      onRequestClose={async () => {
        await dispatch(updateLoginModal(false))
      }}
    >
      <div className="login-modal">
        <header className="login-modal__header pl-5 pr-24.25 pt-25 pb-21 border-b border-gray-200 flex justify-between items-center">
          <h3 className="font-primary text-smd lg:text-md leading-18 tracking-tight">
            {trans('login-required', 'Login Required')}
          </h3>
          <button type="button" className="outline-none focus:outline-none float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={async () => await dispatch(updateLoginModal(false))}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.469684 0.46967C0.762577 0.176777 1.23745 0.176777 1.53034 0.46967L8.00001 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06067 8L15.5304 14.4697C15.8232 14.7626 15.8232 15.2374 15.5304 15.5303C15.2375 15.8232 14.7626 15.8232 14.4697 15.5303L8.00001 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93935 8L0.469684 1.53033C0.176791 1.23744 0.176791 0.762563 0.469684 0.46967Z"
                fill="black"
              />
            </svg>
          </button>
        </header>
        <div className="__body p-5">
          <p className="text-sm lg:text-base leading-18 lg:leading-20 font-normal">
            {trans(
              'wishlist-unavailable',
              'Sorry, Wish Lists are only available for account holders.'
            )}
          </p>
          <p className="text-sm lg:text-base leading-18 lg:leading-20 mt-3">
            {' '}
            {trans('please', 'Please')}{' '}
            <strong className="text-gwOrange">{trans('login', 'Login')}</strong> {trans('or', 'or')}{' '}
            <strong className="text-gwOrange">{trans('sign-up', 'Sign Up')}</strong>.
          </p>
          <div className="flex flex-no-wrap mt-5">
            <Link
              href="/sign-up"
              className="border bg-white border-gray-400 text-black rounded-full w-full mr-2 text-smd uppercase flex justify-center items-center py-3"
            >
              {trans('sign-up', 'Sign Up')}
            </Link>
            <SignInBtn
              linkClass="bg-gwOrange border-gwOrange text-black rounded-full w-full ml-2 text-smd uppercase text-center py-3"
              text={trans('login', 'Login')}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LoginModal
