import { useState, useEffect } from 'react'
import Head from 'next/head'
import Header from '@/components/common/header'
import Footer from '@/components/common/footer'
import CartView from '@/components/cart/cart-view'
import Modal from 'react-modal'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { trackUser } from '@/lib/klaviyo/identify'
import { updateCartModal, updateCountryModal } from '@/redux/actions/uiAction'
import LoginModal from '@/components/common/login-modal'
import CountryModal from '@/components/country-selector/country-selector-modal'
import { getCartLocale } from '@/lib/region-helper'
import { useToasts } from 'react-toast-notifications'
import * as types from '@/redux/actionTypes'
import { trans } from '@/lib/locale-helper'
import Cookies from 'js-cookie'

const DefaultLayout = ({
  children,
  menu,
  includeCart,
  footer,
  isSearchOpened,
  setMounted,
  showOptions,
  setShowOptions,
  isProductPage = false,
}) => {
  const { locale = 'en-US' } = useRouter()
  const router = useRouter()
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  let isPDP = false
  let path = router.asPath
  let removeFirst = path.slice(1)
  let removeLast = removeFirst.slice(0, removeFirst.length - 1)
  let splitPath = removeLast.split('-')
  if (parseInt(splitPath[splitPath.length - 1])) {
    isPDP = true
  }

  const [isMobile, setIsMobile] = useState(false)
  const { isAuthenticated, profile } = useSelector((state) => state.userReducer)
  const { removed_items } = useSelector((state) => state.cartReducer)
  const { cartModal } = useSelector((state) => state.uiReducer)
  const [hideSearchOnOverlay, setHideSearchOnOverlay] = useState(false)
  const [countryModal, setCountryModal] = useState()

  const setShowSearchBox = (event) => {
    let hasClass = event.target.classList.contains('overflow-x-hidden')
    if (hasClass == true) {
      setHideSearchOnOverlay(true)
    } else {
      setHideSearchOnOverlay(false)
    }
  }

  useEffect(() => {
    cartModal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
  }, [cartModal])

  const showCountryModal = (visitedCountry, currentCountry) => {
    setCountryModal({ visitedCountry: visitedCountry, currentCountry: currentCountry })
    dispatch(updateCountryModal(true))
  }
  useEffect(() => {
    Modal.setAppElement('.app')
    dispatch(updateCountryModal(true))
    setTimeout(() => {
      trackUser(isAuthenticated, profile)
    }, 1000)

    setTimeout(async () => {
      setChatBotPosition()
    }, 3000)

    if (typeof window != undefined) {
      setIsMobile(window.innerWidth >= 1024 ? false : true)
      window.addEventListener('resize', function (event) {
        setIsMobile(window.innerWidth >= 1024 ? false : true)
      })
    }
  }, [])

  useEffect(() => {
    if (includeCart === 'false') {
      window.localStorage.removeItem('user_checkout_locale')
    }
  }, [cartModal])

  useEffect(() => {
    setChatBotPosition()
  }, [isMobile])

  const setChatBotPosition = () => {
    const element = document.getElementById('launcher')
    if (element && isMobile) {
      element.style.bottom = '70px'
    } else if (element) {
      element.style.bottom = '0px'
    }
  }

  //remove cart items that are not available to ship
  useEffect(() => {
    if (removed_items && removed_items.length > 0) {
      let cartLocale = getCartLocale(process.env.NEXT_PUBLIC_SITE_REGION)

      let items = ''
      let errorMessage =
        removed_items.length == 1
          ? trans(
              'not-available-to-ship-singular',
              'is removed as it is currently not available to be shipped to country'
            )
          : trans(
              'not-available-to-ship-plural',
              'are removed as they are currently not available to be shipped to country'
            )

      removed_items.forEach((item, index) => {
        let color = item.options.find((color) => color.name == 'Color')
        let size = item.options.find((size) => size.name == 'Size')
        items += `${item.name} ${color && color.value} ${size && size.value}${
          index != removed_items.length - 1 ? ',' : ''
        } `
      })
      // console.log('the remo', items, removed_items)
      addToast(`${items}${errorMessage} ${cartLocale.countryName}`, { appearance: 'error' })
      dispatch({ type: types.CLEAR_CART_REMOVED_ITEMS_REQUEST })
      Cookies.remove('ssCartProducts')
    }
  }, [removed_items])

  return (
    <>
      <div locale={locale}>
        {!isProductPage && (
          <Head>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "url": "${process.env.NEXT_PUBLIC_BASE_URL}",
                  "Name": "GOREWEAR",
                  "alternateName": "GORE® WEAR",
                  "Brand": "GOREWEAR",
                  "logo": "${process.env.NEXT_PUBLIC_BASE_URL}/us/en-us/images/logo.svg"
                }
              `,
              }}
            ></script>
          </Head>
        )}
        <div className={`app min-h-screen ${isPDP ? 'pdp-float-margin' : ''}`}>
          <Header
            menu={menu}
            isSearchOpen={isSearchOpened}
            setMounted={setMounted}
            showOptions={showOptions}
            showCountryModal={showCountryModal}
            setShowOptions={setShowOptions}
            hideSearchOnOverlay={hideSearchOnOverlay}
            setHideSearchOnOverlay={setHideSearchOnOverlay}
            isMobile={isMobile}
            footer={footer}
          />
          <div
            className="content-wrap overflow-hidden relative"
            onClick={(e) => setShowSearchBox(e)}
          >
            <main className="app__content">{children}</main>
            {includeCart && (
              <Modal
                closeTimeoutMS={300}
                isOpen={cartModal}
                className="modal-cart"
                overlayClassName="modal__overlay modal-cart__overlay"
                onRequestClose={async () => {
                  await dispatch(updateCartModal(false))
                }}
              >
                <CartView isOpen={cartModal} />
              </Modal>
            )}
            <LoginModal />
            {countryModal && <CountryModal countryModal={countryModal} />}
            <Footer footer={footer} isMobile={isMobile} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .app {
          max-width: 1920px;
          margin: 0 auto;
        }
        .content-wrap {
          z-index: 9;
        }
      `}</style>
    </>
  )
}

export default DefaultLayout
