import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import Icon from '@/components/icon/icon'
import { useRouter } from 'next/router'
import { enableBodyScroll } from '@/lib/helpers'
import { addForwardSlash } from '@/lib/helpers'
import { navClickGTM } from '@/lib/gtm'

const MobileSubSubMenu = (props) => {
  const [isSubOpened, setIsSubOpened] = useState(false)
  const submenuRef = useRef(null)

  const router = useRouter()

  const closeAndRedirect = (e, url, menuItem) => {
    //GTM
    navClickGTM(
      menuItem?.fields?.enUrl,
      menuItem?.fields?.enTitle,
      `${props?.parentTitle}::${props?.item?.fields?.enTitle}::${menuItem?.fields?.enTitle}`,
      'header'
    )
    //

    e.preventDefault()
    props.setIsOpened(false)
    enableBodyScroll()
    if (props.setMounted) {
      props.setMounted(false)
    }
    router.push(addForwardSlash(url))
  }

  const item = props.item


  useEffect(() => {


    if (isSubOpened) {
      if (submenuRef.current) {
        props.setMenuHeight(`${submenuRef.current.offsetHeight}px`)
      }

    } else {

      if (props.menuHeight != 'auto') {
        props.setMenuHeight(props.menuHeight)
      }
    }

    return () => {
      props.setMenuHeight(props.menuHeight)
    }
  },[isSubOpened])

  return (
    <>
      <div className="mobile-nav-menu-l2 w-full pl-25 pr-31s border-b border-borderGray">
        <div
            tabIndex="-1"
            className="mobile-nav-menu-l2-item cursor-pointer mob-menu-block flex justify-between items-center"
            onClick={() => setIsSubOpened(!isSubOpened)}
          >
            <span className="uppercase font-normal font-primary text-black">{item?.fields?.title}</span>
            <Icon
              className={`flex-shrink-0`}
              viewBox={`0 0 12 12`}
              size={12}
              fill="#E36F22"
              icon="arrow_right"
            />
          </div>
        {
          isSubOpened && (
            <div ref={submenuRef} className="mobile-nav-menu-l3 absolute left-0 top-0 right-0 z-20">
              <div className="flex justify-left bg-white items-center pl-25 pr-31s cursor-pointer">
                <div className={`mobile-nav-menu-back w-full py-5 h-full inline-flex items-center font-primary`} onClick={() => setIsSubOpened(false)}>
                  <Icon
                    className={`flex-shrink-0`}
                    viewBox={`0 0 12 12`}
                    size={12}
                    fill="#00000"
                    icon="arrow_left"
                  />
                    <span
                      style={{borderBottom: '4px solid #e36f22', paddingBottom: '5px' }}
                      className={`ml-5 text-base font-normal uppercase mobile-menu-link font-primary leading-14`}
                    >
                      {item?.fields?.title}
                    </span>
                  </div>
              </div>
              <div className="mobile-nav-menu-l3-items">
                {item?.fields?.menuItems?.map((menuItem, menuItemIndex) => {
                  return (
                    <div
                      key={`${menuItemIndex}-menuItem.fields?.title`}
                      className={`mobile-nav-menu-l3-item flex bg-white items-center pl-25 pr-31s mob-menu-block border-b border-borderGray`}
                    >
                      <Link
                          tabIndex={-1}
                          className={`mobile-nav-menu-l3-item-link w-full h-full flex items-center justify-between ${menuItem.fields.highlighted ? 'text-primary' : ''}`}
                          href={addForwardSlash(menuItem.fields.url)}
                          onClick={(e) => closeAndRedirect(e, menuItem.fields.url, menuItem)}
                        >
                          <span className="font-normal uppercase mobile-menu-link font-primary">
                            {menuItem.fields?.title}
                          </span>
                          <Icon
                            className={`flex-shrink-0`}
                            viewBox={`0 0 12 12`}
                            size={12}
                            fill="#E36F22"
                            icon="arrow_right"
                          />
                        </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      </div>
      <style jsx>{
        `
          .mobile-nav-menu-l2-item span,
          .mobile-nav-menu-l3-item span {
            font-size: 14px;
            line-height: 12px;
          }
        `
      }</style>
    </>
  )
}

export default MobileSubSubMenu
