import Loader from 'react-loader-spinner'
import styleConfig from '@/styles/style-config'

const SpinnerComponent = (props) => {
  const { show, size = 50, minHeight = 200, fullHeight = false } = props
  const containerClass = show ? 'spin-container spin-blur' : 'spin-container'
  return (
    <>
      <div className={`spin-nested-loading  ${fullHeight && 'h-full'}`}>
        {show ? (
          <div className="spin-load">
            <Loader type="Oval" color={styleConfig.theme.colors.black} height={size} width={size} />
          </div>
        ) : null}
        <div className={`${containerClass} ${fullHeight && 'h-full'}`}> {props.children}</div>
      </div>
      <style jsx>{`
        .spin-blur {
          clear: both;
          overflow: hidden;
          opacity: 0.5;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
        }
        .spin-nested-loading {
          position: relative;
          min-height: ${minHeight}px;
        }
        .spin-container {
          position: relative;
          -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
        }
        .spin-load {
          position: absolute;
          top: 45%;
          left: 45%;
          z-index: 4;
        }
      `}</style>
    </>
  )
}

export { SpinnerComponent }
