import { useState, useEffect, useCallback } from 'react'
import Icon from '@/components/icon/icon'
import RelativePortal from 'react-relative-portal'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { updateItemQty } from '@/redux/actions/cartAction'
import ErrorItemsNode from '@/components/common/error-items-node'
import { debounce } from '@/lib/lodash'
import { trans } from '@/lib/locale-helper'
import { DebounceInput } from 'react-debounce-input'
import { updateQuantity } from '@/lib/cart-helper'

const QtyDropdownInput = ({item}) => {

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const [inputValue, setInputValue] = useState(item.quantity)

  const { addToast } = useToasts()

  let maxInput = 9

  let inventoryLevel = item.variant.inventory_level

  let allowedQty = inventoryLevel <= maxInput ? inventoryLevel : maxInput

  useEffect(() => {

    let scrollable = document.querySelector('.cart__items')

    if (scrollable) {
      scrollable.addEventListener('scroll', () => {
        setShow(false)
      })

      return () => {
        scrollable.removeEventListener('scroll', () => {
          setShow(false)
        })
      }
    }

  },[])

  const handleSelect = (qty) => {
    let quantityChange;
    if (qty > inputValue) {
      quantityChange = 'add'
    } else if (qty < inputValue) {
      quantityChange = 'less'
    } 

    updateQuantity(item, dispatch, qty, quantityChange)
    setInputValue(qty)
    setShow(false)
  }

  const debounceLoadData = useCallback(
    debounce((nextValue, isMax = 0) => {
      if (isMax) {
        addToast(
          <ErrorItemsNode errors={[`${ trans('maximum-stock-is','maximum stock quantity is') } ${item.inventory_level}`]} />,
          {
            appearance: 'error',
          }
        )
      }
    /*   setInputValue(nextValue) */
      updateQuantity(item, dispatch, nextValue)
    }, 500),
    [debounce, item.quantity]
  )

  const QtyOnChange = async (e) => {
    let val = parseInt(e.target.value)

    let tempValue = val

    if (Number.isNaN(val)) {
      tempValue = ''
    }

    setInputValue(tempValue)

    if (val <= 0 || Number.isNaN(val)) {
      val = 1
    }

    if (val > 0) {
      if (item.inventory_level < val) {
        await dispatch(updateItemQty(item.id, item.inventory_level))
        debounceLoadData(item.inventory_level, 1)
      } else {
        await dispatch(updateItemQty(item.id, val))
        debounceLoadData(val)
      }
    } else {
      await dispatch(updateItemQty(item.id, ''))
    }


  }

  useEffect(() => {
    setInputValue(item.quantity)
  },[item])

  return (
    <>
    <div className="qty-dropdown-input  relative  border border-gray-400 bg-white flex items-center">
        <DebounceInput
          debounceTimeout={500}
          value={inputValue}
          onChange={QtyOnChange}
          className="qty-dropdown-input__input text-primaryA outline-none focus:outline-none relative z-20" />
        <Icon
          className={`cursor-pointer text-black fill-current w-5 h-5 relative z-20 flex-shrink-0`}
          viewBox={`0 0 24 24`}
          size={24}
          icon='chevron-down'
          onClick={() => setShow(!show)}
        />
        <div className="absolute inset-0 z-10">
          <RelativePortal
              component="div"
              left={0}
              top={42}
              onOutClick={() => setShow(false)}
            >

              {
                show &&
                <div className="qty-dropdown-input__options relative w-full bg-white text-primaryA z-50">
                    {
                      [...Array(allowedQty)].map((e, i) => (
                        <div
                          className="qty-dropdown-input__option flex items-center hover:bg-accent"
                          key={Math.random()}onClick={() => handleSelect(i+1)}>{i+1}</div>
                      ))
                    }
                </div>
              }

            </RelativePortal>
        </div>
    </div>
    </>
  )
}

export default QtyDropdownInput