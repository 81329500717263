import { useEffect, useState } from 'react'
import Image from 'next/image'
import styleConfig from '@/styles/style-config'

const deviceSizeMap = [
  'xxs',
  'md',
  'lg',
  'xl',
  'xxl',
  'g_sm',
  'g_md',
  'custom_sm',
  'custom_md',
].reduce(
  (map, sizeKey) => ({
    ...map,
    [sizeKey]: Number(styleConfig.theme.screens[sizeKey].replace('px', '')),
  }),
  {}
)

const defaultSizes = [
  `(min-width: ${deviceSizeMap.xxl}px) ${deviceSizeMap.xxl}px`,
  `(min-width: ${deviceSizeMap.xl}px) ${deviceSizeMap.xl}px`,
  `(min-width: ${deviceSizeMap.lg}px) ${deviceSizeMap.lg}px`,
  `${deviceSizeMap.md}px`,
].join(',')


const handleContentfulImage = ({ src = '', width, quality }) => {

  const url = new URL(src)
  const params = new URLSearchParams(url.search)
  const customHeight = url.searchParams.get('h')


  params.append('fm', 'webp')

  if (width) {
    params.append('w', width)
  }

  if (customHeight) {
    params.append('h', customHeight)
  }

  if (quality) {
    params.append('q', quality)
  }

  url.search = params.toString()

  return url?.toString()

}

const handleBigCommerceImage = ({ src = '', width }) => {
  // Calculate closest NextJS Supported image size
  let customWidth = [256, 384, 640, 750, 828].reduce((a, b) => {
    return Math.abs(b - width) < Math.abs(a - width) ? b : a
  })

  if (!customWidth) {
    customWidth = width // Set a medium default value
  }

  let customSrc
  let urlPartials = src.split('original')
  if (urlPartials[1]) {
    customSrc = `${urlPartials[0]}${customWidth}w${urlPartials[1]}`
  } else {
    customSrc = src
  }

  if (process.env.NEXT_PUBLIC_IS_VERCEL === 'true') {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}&q=90&fm=webp`
  }

  return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}px&q=90&fm=webp`
}


const loader = (loadedProps) => {
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContentfulImage(loadedProps)
  if (src?.includes('bigcommerce')) return handleBigCommerceImage(loadedProps)
  return src
}

const MultiImage = ({
  src,
  srcMap,
  alt = '',
  className,
  loading = 'lazy',
  quality = 85,
  priority = false,
  width,
  height,
  sizes}) => {

  const defaultImage = srcMap ? srcMap?.mobile || srcMap?.tablet || srcMap?.desktop || src : src
  const [imageData, setImageData] = useState({
    src: defaultImage,
    loaded: false
  })
  let conditionalProps = {}

  if (priority) {
    conditionalProps['priority'] = true
  } else {
    conditionalProps['loading'] = loading
  }

  const handleResize = () => {
    try {
      if (srcMap) {
        if (window?.innerWidth < deviceSizeMap['md']) {
          setImageData({src: srcMap?.mobile, loaded: true})
        } else if (window?.innerWidth >= deviceSizeMap['md'] &&  window?.innerWidth < deviceSizeMap['lg']) {
          setImageData({src: srcMap?.tablet, loaded: true})
        } else if (window?.innerWidth >= deviceSizeMap['lg']) {
          setImageData({src: srcMap?.desktop, loaded: true})
        }
      }
    } catch (error) {
      console.log('handleResize error', error)
    }
  }

  useEffect(() => {

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])

  return (
    <>
      <Image
        loader={loader}
        className={className}
        src={imageData?.src}
        sizes={sizes || defaultSizes}
        alt={alt}
        fill={height || width ? false : true}
        quality={quality}
        height={height}
        width={width}
        {...conditionalProps}
      />

       {
        !imageData?.loaded &&
        <>
          <noscript>
            <style dangerouslySetInnerHTML={{ __html: ".no-js { display: none; }" }} />
          </noscript>
          <div className="no-js base-image-blur absolute inset-0 bg-white/30"></div>
        </>
       }
      <style jsx>{
        `
         .base-image-blur {
          backdrop-filter: blur(40px);
         }
        `
      }</style>
    </>
  )

}

export default MultiImage