import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import Icon from '@/components/icon/icon'
import nextConfig from 'next/config'
import { getRegion } from '@/lib/region-helper'

const { publicRuntimeConfig } = nextConfig()

const CountrySelectorCurrent = (props) => {

  const countryList = props.countryList
  const country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const countryLocale = country.countryCode == 'US' ? 'us/en-us' : country.domain
  const countryData = countryList.find(item => item.prefix == countryLocale.toLowerCase())

  const router = useRouter()

  const currentCountry = countryData

  useEffect(() => {
   checkUriLang()
  //  setCountryDisplay()
  },[])

  const checkUriLang = () => {

    const path = router.asPath
    const uriSegments = (path.split('/')).filter(n => n)

    if (uriSegments.length > 2) {
      const prefix = `${uriSegments[0]}/${uriSegments[1]}`

      const countryData = countryList.find(item => item.prefix == prefix)

      if (typeof countryData === 'undefined') {
        return
      }

      const countryCookie = Cookies.get('current_country')

      if (countryCookie !== countryData.locale) {
        Cookies.set('current_country', countryData.locale, { expires: 7 })
      }
    }
  }

  // const setCountryDisplay = () => {
  //   let pathname = window.location.pathname.split('/').filter(n => n)
  //   let countryCookie = ''
  //   if(pathname[0] == 'us') {
  //     countryCookie = pathname[0]+'/'+pathname[1]
  //   }
  //   else {
  //     countryCookie = pathname[0]
  //   }
  //   const countryLocale = (countryCookie !== '') ? countryCookie : 'us/en-us'

  //   const countryData = countryList.find(item => item.prefix == countryLocale)

  //   setCurrentCountry(countryData)
  // }

  return (
    <div className={`country-selector-btn ${props.className} ${props.show == false ? 'active': ''}`}>
      {/* <span className={`flag-icon flag-icon-us`}></span>
      <span className="ml-2 uppercase text-xs">United States ($)</span> */}
      {
        (currentCountry && currentCountry.flag) && (
          <>
          <img alt={currentCountry.text} className={`flag-icon`} src={`${publicRuntimeConfig.basePath}/flags-svg/${currentCountry.flag}.svg`}/>
          <span className="ml-2 font-normal font-tertiary text-gray-400 tracking-tightest text-xsm leading-16r uppercase">{ currentCountry.parent_country || currentCountry.text }</span>
          <Icon
            className={`cursor-pointer text-gray-400 fill-current country-chevron`}
            viewBox={`0 0 20 20`}
            size={20}
            icon={props.show == false ? 'chevron-down' : 'chevron-up'}
            />
          </>
        )
      }
    </div>
  )
}

export default CountrySelectorCurrent