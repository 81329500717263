import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useDispatch, useSelector, } from 'react-redux'
import SignInLink from '@/components/common/sign-in-link'
import MobileSubMenu from '@/components/common/mobile-submenu'
import countryList from '@/config/countries'
import { userSignOut } from '@/redux/actions/userAction'
import CountrySelectorCurrent from '@/components/country-selector/country-selector-current'
import CountrySelectorOptions from '@/components/country-selector/country-selector-options'
import { isEmpty } from '@/lib/lodash'
import { addForwardSlash, enableBodyScroll, getCountrySelectorData } from '@/lib/helpers'
import { trans } from '@/lib/locale-helper'
import { getRegionNumber } from '@/lib/region-helper'
import { navClickGTM } from '@/lib/gtm'

const MobileMenu = (props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.userReducer)
  const [menuHeight, setMenuHeight] = useState('auto')
  const navRef = useRef(null)

  const onSignOut = async () => {
    try {
      const savedCountry = getCountrySelectorData()
      await dispatch(userSignOut())
      window.location = `${process.env.NEXT_PUBLIC_BASE_URL}/${savedCountry?.prefix}/home`
    } catch (err) {
      // Handle Error Here
      // console.log('onSignOut errObj', err)
    }
  }

  let services = []
  const servicesLink = props?.footer?.menus.find(
    (item) => item.fields && item.fields.entryTitle.trim() == 'Service & Support'
  )

  if (servicesLink) {
    services = servicesLink.fields
  }

  useEffect(() => {
    if (!props.isOpened) {
      setMenuHeight('auto')
    } else {
      if (navRef?.current) {
        navRef.current.scrollTop = 0
      }
    }
  },[props.isOpened])

  return (
    <>
      <div
        ref={navRef}
        className={`mobile-menu ${
          props.isOpened ? 'slide-in' : 'slide-out'
        } absolute z-20 w-full h-screen overflow-auto bg-black text-white pb-236`}
      >
        <div>
          <div className="pt-4 bg-white">
            <div className="mobile-nav-menu-l1-wrap relative overflow-hidden" style={{height: menuHeight}}>
            {props?.menu?.length > 0 &&
              props?.menu.map((item, index) => {
                if (!isEmpty(item) && item.fields) {
                  let title = ''
                  let url = ''

                  if (item.fields.title === 'Shop Men') {
                    title = 'Shop Men'
                    url = 'shop-men'
                  } else if (item.fields.title === 'Shop Women') {
                    title = 'Shop Women'
                    url = 'shop-women'
                  } else {
                    title = item.fields.title
                    url = item.fields.url
                  }

                  return (
                    <MobileSubMenu
                      title={title}
                      categories={props.categories}
                      setIsOpened={props.setIsOpened}
                      url={url}
                      key={`${item.title}-${index}`}
                      submenus={item.fields.submenu[0]}
                      titleDefault={item?.fields?.enTitle}
                      urlDefault={item?.fields?.enUrl}
                      setMounted={props.setMounted}
                      setMenuHeight={setMenuHeight}
                      isLast={props?.menu?.length == index+1 ? true : false}
                      isParentOpen={props.isOpened}
                    />
                  )
                }
              })}
            </div>
          </div>
          {isAuthenticated && (
            <div>
              <div
                className="flex mob-menu-block justify-between uppercase items-center px-6 py-3 border-b cursor-pointer border-gray-800"
                onClick={enableBodyScroll}
              >
                <Link
                  href={`/account/dashboard`}
                  tabIndex={-1}
                  className="text-base font-normal leading-14"
                  onClick={() => {
                    navClickGTM('/account/dashboard', 'My Account', 'Account', 'header')
                  }}
                >
                  {trans('my-account', 'My Account')}
                </Link>
              </div>
            </div>
          )}
          {isAuthenticated && (
            <div>
              <div
                className="flex mob-menu-block justify-between uppercase items-center px-6 py-3 border-b cursor-pointer border-gray-800"
                onClick={enableBodyScroll}
              >
                <a
                  tabIndex="-1"
                  className="text-base font-normal leading-14"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    onSignOut()
                  }}
                >
                  {trans('sign-out', 'Sign out')}
                </a>
              </div>
            </div>
          )}
          {!isAuthenticated && (
            <div>
              <div
                className="flex mob-menu-block justify-between items-center uppercase px-6 py-3 border-b cursor-pointer border-gray-800"
                onClick={enableBodyScroll}
              >
                <Link
                  href="/sign-up"
                  tabIndex={-1}
                  className="text-base font-normal leading-14"
                  onClick={() => {
                    navClickGTM('/sign-up', 'Sign Up', 'Sign Up', 'header')
                  }}
                >
                  {trans('sign-up', 'Sign up')}
                </Link>
              </div>
              <div
                className="flex mob-menu-block justify-between items-center uppercase px-6 py-3 border-b cursor-pointer border-gray-800 text-base font-normal leading-14"
                onClick={enableBodyScroll}
              >
                <SignInLink noTab="true" text={trans('login', 'Login')} />
              </div>
            </div>
          )}
          {services && (
            <div className="px-6 h-auto">
              <div className="flex justify-between uppercase items-center py-5">
                <a tabIndex="-1" className="text-sm">
                  {trans('service-support', 'Service & Support')}
                </a>
              </div>
              <ul className="text-sm space-y-3 list-none">
                {services?.menuItems?.map((menuItem, menuItemIndex) => {
                  if (menuItem && menuItem.fields) {
                    return (
                      <li key={`${menuItem.fields.title}-${menuItemIndex}`}>
                        <Link href={addForwardSlash(menuItem.fields.url)} tabIndex={-1}>
                          {menuItem.fields.title}
                        </Link>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          )}
          <div className="uppercase px-6 py-5 text-black font-tertiary text-xsm leading-16">
            <span className="text-gray-400">
              {trans('call-us-toll-free', 'Call us toll-free: ')}
            </span>{' '}
            <a href={`tel:${getRegionNumber()}`}>{getRegionNumber()}</a>
          </div>
          <div className="flex justify-between items-center px-6 py-5" style={{ fontSize: '10px' }}>
            <div className="flex items-center">
              <div className={`country-selector-mobile`}>
                <CountrySelectorCurrent
                  show={props.showCountrySelector}
                  className="country-selector-mobile__current flex items-center cursor-pointer"
                  countryList={countryList}
                />
              </div>
            </div>
          </div>
          {props.showCountrySelector && (
            <CountrySelectorOptions
              show={props.showCountrySelector}
              className="country-selector__options country-selector-mobile w-full flex flex-row border-t border-gray-800"
              countryList={countryList}
              perChunk={Math.floor(countryList.length / 2) + 1}
            />
          )}
        </div>
      </div>
      <style jsx>
        {`
          .mobile-menu {
            top: 100%;
            transition: all 0.3s ease-in-out;
            will-change: transform;
            min-height: fill-available;
          }

          .pb-236 {
            padding-bottom: 14.75rem;
          }

          .mobile-menu.slide-in {
            opacity: 1;
            transform: translateX(0%);
          }

          .mobile-menu.slide-out {
            opacity: 0;
            transform: translateX(-100%);
          }

          @media (min-width: 1025px) {
            .mobile-menu {
              display: none;
            }
          }

          ul,
          ol {
            list-style: none;
          }
        `}
      </style>
    </>
  )
}

export default MobileMenu
