import { getCartLocale } from '@/lib/region-helper'

export const getShippingMethods = (countryCode = null) => {

  try {

    const shippingMethods = require('@/public/shipping-methods.json')

    const cartLocale = getCartLocale(process.env.NEXT_PUBLIC_SITE_REGION)

    const newCountryCode = countryCode ? countryCode : cartLocale.countryCode

    if (cartLocale && shippingMethods[newCountryCode?.toLowerCase()]) {
      return shippingMethods[newCountryCode?.toLowerCase()]
    }

    return null
  } catch (error) {
    console.error('getShippingMethods error: ', error)
    return null
  }
}

export const getShippingPrice = (cartPrice) => {

  try {
    const methods = getShippingMethods()

    if (methods && methods.length > 0) {

      let freeShipping = methods.find(method => method?.type == 'freeshipping' && method?.enabled)

      if (freeShipping) {

        let minumumSubtotal = freeShipping?.settings?.carrier_options?.minimum_sub_total
        if (Number(cartPrice) > Number(minumumSubtotal)) {
          return 0
        }
      }


      let nonFreeShippingMethods = methods.filter(method => method?.type != 'freeshipping' && method?.enabled)

      if (nonFreeShippingMethods && nonFreeShippingMethods.length > 0) {

        let lowestMethod =  nonFreeShippingMethods.reduce((acc, loc) =>
          acc?.settings?.rate < loc?.settings?.rate? acc : loc)

         if (lowestMethod) {
          return lowestMethod?.settings?.rate
         }
      }
    }

    return null
  } catch (error) {
    console.error('getShippingPrice error: ', error)
    return null
  }

}